import {
  CheckOutlined,
  CopyOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  FileSyncOutlined,
  LinkOutlined,
  MenuOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import { useQueryClient } from "@tanstack/react-query";
import { Badge, Dropdown, FormInstance, MenuProps, Tooltip, Typography, message, App } from "antd";
import { Dispatch, SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactMoment from "react-moment";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { JOB_QUERY_KEY, removeJobAd } from "@app/api/job-ads.api";
import { Button } from "@app/components/ui/Button/Button";
import { RoutePaths } from "@app/features/routes/types/routes.types";
import { getJobPageUrl } from "@app/helpers/job-ads.helper";
import { ModelNames } from "@app/store/models/models";
import { RootState } from "@app/store/store";
import { EJobAdStatus, JobAdFormDef, JobAdNavigationState } from "@app/types/job-ads.types";
import { jobAdPaidModal } from "./components/JobAdPaidModal/JobAdPaidModal";

const NEW_PATH = "new";

type JobAdDetailsHeaderItemsProps = {
  onSaveJobAd: () => void;
  form: FormInstance<JobAdFormDef>;
  hasChangedValues: boolean;
  setHasChangedValues: Dispatch<SetStateAction<boolean>>;
  hasErrors: boolean;
};

export const jobAdDetailsHeaderItems = ({
  onSaveJobAd,
  form,
  hasChangedValues,
  setHasChangedValues,
  hasErrors,
}: JobAdDetailsHeaderItemsProps) => {
  const app = App.useApp();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const hasPaidForJobAds = useSelector(
    (state: RootState) => state.companyInfo.companyInfo.hasPaidForJobAds
  );
  const navigate = useNavigate();
  const { id } = useParams();
  const jobAdData = form.getFieldsValue(true);
  const [loading, setLoading] = useState(false);
  const isNewJobAd = id === NEW_PATH;
  const isPublished = jobAdData?.status === EJobAdStatus.PUBLISHED;
  const liveURL = jobAdData?.fullSlug ? getJobPageUrl(jobAdData) : undefined;

  // FUNCTIONS
  const handleSubmit = () => {
    form.submit();
  };

  const toggleJobAdStatus = async () => {
    const currentStatus = form.getFieldValue("status");
    form.setFieldValue(
      "status",
      currentStatus === EJobAdStatus.DRAFT ? EJobAdStatus.PUBLISHED : EJobAdStatus.DRAFT
    );
    await onSaveJobAd();
    message.success({ content: t("Saved!"), key: ModelNames.JOB_ADS, duration: 1 });
  };

  const handleStatusChange = async () => {
    const currentStatus = form.getFieldValue("status");
    if (!hasPaidForJobAds) {
      jobAdPaidModal(t);
      return;
    }

    app.modal.confirm({
      ...(currentStatus === EJobAdStatus.DRAFT
        ? {
            title: t("Are you sure you want to publish the job ad?"),
            content: t("When you publish the job ad, then it will be available for the public."),
          }
        : {
            title: t("Are you sure you want to unpublish the job ad?"),
            content: t(
              "When you unpublish the job ad, then it will not be available for the public anymore."
            ),
          }),
      icon: <ExclamationCircleOutlined />,
      okText: t("Yes"),
      okType: "danger",
      cancelText: t("No"),
      onOk() {
        toggleJobAdStatus();
      },
    });
  };

  const PublishButton = () => {
    const content = (
      <Button
        id="publish-job-button"
        disabled={isNewJobAd || loading}
        type={isPublished ? "default" : "primary"}
        onClick={handleStatusChange}
        icon={isPublished ? <FileSyncOutlined /> : <CheckOutlined />}
      >
        {isPublished ? t("Unpublish") : t("Publish")}
      </Button>
    );

    if (!hasPaidForJobAds) {
      return <Tooltip title={t("Publishing Job Ads requires a subscription")}>{content}</Tooltip>;
    }

    return content;
  };

  const onDeleteAd = async () => {
    if (!jobAdData) return;
    try {
      setLoading(true);
      setHasChangedValues(false);
      await removeJobAd(jobAdData.id);
      queryClient.invalidateQueries({
        queryKey: [JOB_QUERY_KEY],
      });
      message.success({ content: t("Done"), key: ModelNames.JOB_ADS, duration: 1 });
      navigate(RoutePaths.JOB_ADS, { replace: true });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const onDuplicateAd = async () => {
    if (!jobAdData) return;
    navigate(RoutePaths.JOB_AD_NEW, {
      replace: true,
      state: { duplicateJobAdId: jobAdData.id } as JobAdNavigationState,
    });
  };

  // MENU
  const handleMenuClick: MenuProps["onClick"] = (e) => {
    if (e.key === "duplicate") {
      onDuplicateAd();
    } else if (e.key === "delete") {
      app.modal.confirm({
        title: t("Are you sure you want to delete this?"),
        icon: <ExclamationCircleOutlined />,
        content: t("You will not be able to undo this."),
        okText: t("Yes"),
        okType: "danger",
        cancelText: t("No"),
        onOk() {
          onDeleteAd();
        },
      });
    }
  };
  const menuProps = {
    items: [
      {
        key: "duplicate",
        label: t("Duplicate"),
        icon: <CopyOutlined />,
        disabled: isNewJobAd,
      },
      {
        key: "delete",
        label: t("Remove"),
        icon: <DeleteOutlined />,
        disabled: isNewJobAd,
      },
    ],
    onClick: handleMenuClick,
  };

  const headerItems = [
    !isNewJobAd && jobAdData?.updatedAt && (
      <Typography.Text key="updatedAt" type="secondary" italic style={{ fontSize: 14 }}>
        {t("Updated")} <ReactMoment fromNow>{jobAdData.updatedAt}</ReactMoment>
      </Typography.Text>
    ),
    !isNewJobAd && jobAdData?.status && (
      <Badge
        key="status"
        status={jobAdData.status === EJobAdStatus.DRAFT ? "warning" : "success"}
        text={t(jobAdData.status || EJobAdStatus.DRAFT)}
      />
    ),
    <PublishButton key="publish" />,
    <a key="view" target="_blank" rel="noreferrer" href={liveURL}>
      <Button disabled={isNewJobAd || !liveURL} type="default" icon={<LinkOutlined />}>
        {t("View")}
      </Button>
    </a>,
    <Button
      id="save-job-button"
      key="save"
      disabled={hasErrors || !hasChangedValues}
      onClick={handleSubmit}
      htmlType="submit"
      type="primary"
      icon={<SaveOutlined />}
    >
      {t("Save")}
    </Button>,
    <Dropdown key="menu" menu={menuProps} placement="bottomRight" trigger={["click"]}>
      <Button icon={<MenuOutlined />} />
    </Dropdown>,
  ].filter(Boolean) as JSX.Element[];

  return headerItems;
};
