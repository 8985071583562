import { Space } from "antd";
import { useTranslation } from "react-i18next";
import {
  SelectAutocomplete,
  SelectAutocompleteProps,
} from "@app/components/ui/SelectAutocomplete/SelectAutocomplete";
import { StatusBadge } from "@app/components/ui/StatusBadge/StatusBadge";
import { EJobAdStatus } from "@app/types/job-ads.types";

export const StatusFilter = (props: SelectAutocompleteProps) => {
  const { t } = useTranslation();

  const choices = Object.values(EJobAdStatus).map((i) => ({
    value: i,
    label: (
      <Space size={4}>
        <StatusBadge isSuccess={i === EJobAdStatus.PUBLISHED} />{" "}
        <span style={{ textTransform: "capitalize" }}>{t(i)}</span>
      </Space>
    ),
  }));

  return <SelectAutocomplete placeholder={t("Status")} options={choices} allowClear {...props} />;
};
