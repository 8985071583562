import { ClockCircleOutlined, DeleteOutlined } from "@ant-design/icons";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  Alert,
  App,
  Button,
  Col,
  Form,
  Input,
  Popover,
  Row,
  Skeleton,
  Space,
  Typography,
} from "antd";
import range from "lodash/range";
import moment from "moment-timezone";
import { ComponentProps, useState } from "react";
import { useSelector } from "react-redux";
import { MomentDatePicker } from "@app/components/ui/DatePicker";
import { DateFormats } from "@app/constants/date.constants";
import { useUserProgressSA } from "@app/features/super-admin/api/super-admin.progress.api";
import {
  createReminderSA,
  deleteReminderSA,
  Reminder,
  remindersQueryKey,
  useRemindersSA,
} from "@app/features/super-admin/api/super-admin.reminder.api";
import { isTMTeamUser } from "@app/helpers/auth.helper";
import { RootState } from "@app/store/store";

export const Reminders = ({
  workerProfileProgressId,
  superAdminId,
}: {
  workerProfileProgressId?: string;
  superAdminId?: string;
}) => {
  const queryClient = useQueryClient();
  const remindersQuery = useRemindersSA({
    workerProfileProgressId,
    superAdminId,
  });

  return (
    <div
      style={{
        paddingBottom: "16px",
        width: "100%",
      }}
    >
      {remindersQuery.isError && <Alert message="Failed to load reminders" type="error" />}
      <RemindersList
        reminders={remindersQuery.data || []}
        onDelete={() => {
          queryClient.invalidateQueries({
            queryKey: remindersQueryKey,
          });
        }}
      />
    </div>
  );
};

export const RemindersList = ({
  reminders,
  onNameClick,
  onDelete,
}: {
  reminders: Reminder[];
  onNameClick?: (reminder: Reminder) => void;
  onDelete: () => void;
}) => {
  const app = App.useApp();
  const deleteReminder = useMutation({
    mutationFn: deleteReminderSA,
    onSettled: () => {
      onDelete();
    },
  });

  return (
    <>
      {reminders.length !== 0 && (
        <Space direction="vertical" style={{ width: "100%" }}>
          {reminders.map((reminder) => (
            <Alert
              key={reminder.id}
              message={
                <Row wrap={false}>
                  <Col flex="1">
                    {onNameClick && (
                      <Typography.Link
                        style={{
                          fontSize: "12px",
                        }}
                        onClick={() => onNameClick(reminder)}
                      >
                        <ReminderUserName reminder={reminder} />
                      </Typography.Link>
                    )}
                    <div>
                      <Typography.Text
                        type="secondary"
                        style={{
                          fontSize: "12px",
                        }}
                      >
                        <ClockCircleOutlined style={{ marginRight: "4px" }} />
                        {moment(reminder.date).format(DateFormats.FULL_DATE_TIME)}
                      </Typography.Text>
                    </div>
                    <Typography.Text style={{ whiteSpace: "pre-line" }}>
                      {reminder.description}
                    </Typography.Text>
                  </Col>
                  <Col flex="none">
                    <Button
                      icon={<DeleteOutlined />}
                      size="small"
                      type="text"
                      loading={deleteReminder.isPending}
                      onClick={() => {
                        app.modal.confirm({
                          title: "Are you sure you want to delete this reminder?",
                          onOk: () => {
                            deleteReminder.mutate({
                              id: reminder.id,
                            });
                          },
                        });
                      }}
                    />
                  </Col>
                </Row>
              }
            />
          ))}
        </Space>
      )}
    </>
  );
};

export const ReminderUserName = ({ reminder }: { reminder: Reminder }) => {
  const progressQuery = useUserProgressSA({
    id: reminder.workerProfileProgressId,
  });

  return (
    <>
      <Skeleton loading={progressQuery.isLoading} paragraph={false}>
        {progressQuery.data?.workerProfile.firstName} {progressQuery.data?.workerProfile.lastName}
      </Skeleton>
    </>
  );
};

const lastSelectableDate = moment().add(120, "day");
const disabledDate: ComponentProps<typeof MomentDatePicker>["disabledDate"] = (current) => {
  return current && (current < moment().startOf("day") || current > lastSelectableDate);
};
const disabledTime = () => {
  return {
    disabledHours: () => range(0, 24).filter((hour) => hour < 7 || hour > 21),
    disabledMinutes: () => range(0, 60).filter((second) => second % 15 !== 0),
  };
};

export const RemindMeButton = ({
  workerProfileProgressId,
  defaultDescription,
}: {
  workerProfileProgressId: string;
  defaultDescription: string;
}) => {
  const [form] = Form.useForm<{
    date: moment.Moment;
    description: string;
  }>();
  const queryClient = useQueryClient();
  const [isOpen, setIsOpen] = useState(false);
  const authUser = useSelector((state: RootState) => state.auth.user);
  const parsedSuperToken = useSelector((state: RootState) => state.superAdmin.parsedSuperToken);

  const createReminder = useMutation({
    mutationFn: createReminderSA,
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: remindersQueryKey,
      });
    },
    onSuccess: () => {
      setIsOpen(false);
      form.resetFields();
    },
  });

  const isTMteam = isTMTeamUser(authUser);

  const content = (
    <Form
      form={form}
      layout="vertical"
      initialValues={{
        date: moment().add(90, "minutes").startOf("hour"),
        description: defaultDescription,
      }}
      onFinish={(values) => {
        if (parsedSuperToken) {
          createReminder.mutate({
            superAdminId: parsedSuperToken.id,
            workerProfileProgressId,
            date: values.date.toISOString(),
            description: values.description,
          });
        }
      }}
    >
      {!isTMteam && (
        <Alert
          showIcon
          type="error"
          message="Please log in with your Talent Management user to use reminders"
        />
      )}
      <Space direction="vertical">
        <Form.Item
          label="Date"
          name="date"
          rules={[
            {
              required: true,
            },
            {
              validator: (_, value) => {
                if (value && moment.isMoment(value) && value.isBefore(moment())) {
                  return Promise.reject("Select a future date");
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <MomentDatePicker
            showTime={{
              hideDisabledOptions: true,
              showSecond: false,
            }}
            disabledDate={disabledDate}
            disabledTime={disabledTime}
            presets={[
              {
                label: "In 1 hour",
                value: () => roundToNearestQuarterHour(moment().add(1, "hour")),
              },
              {
                label: "In 3 hours",
                value: () => roundToNearestQuarterHour(moment().add(3, "hour")),
              },
              {
                label: "In 6 hours",
                value: () => roundToNearestQuarterHour(moment().add(6, "hour")),
              },
              {
                label: "In 1 day",
                value: () => roundToNearestQuarterHour(moment().add(1, "day")),
              },
              {
                label: "In 3 days",
                value: () => roundToNearestQuarterHour(moment().add(3, "day")),
              },
              {
                label: "In 1 week",
                value: () => roundToNearestQuarterHour(moment().add(1, "week")),
              },
              {
                label: "In 2 weeks",
                value: () => roundToNearestQuarterHour(moment().add(2, "week")),
              },
            ]}
          />
        </Form.Item>
        <Form.Item
          label="Description"
          name="description"
          rules={[
            {
              required: true,
              whitespace: true,
            },
          ]}
        >
          <Input.TextArea rows={4} placeholder="Enter..." />
        </Form.Item>
        <Form.Item noStyle>
          <Button
            type="primary"
            htmlType="submit"
            loading={createReminder.isPending}
            style={{
              width: "100%",
            }}
            disabled={!isTMteam}
          >
            Create
          </Button>
        </Form.Item>
      </Space>
    </Form>
  );

  return (
    <Popover
      title="Create a reminder"
      trigger="click"
      content={content}
      open={isOpen}
      onOpenChange={(newOpen) => {
        setIsOpen(newOpen);

        if (!newOpen) {
          form.resetFields();
        }
      }}
    >
      <Button>🔔 Remind me</Button>
    </Popover>
  );
};

const roundToNearestQuarterHour = (date: moment.Moment) => {
  const remainder = 15 - (date.minute() % 15);
  return date.clone().add(remainder, "minutes").set("second", 0);
};
