import { useQuery } from "@tanstack/react-query";
import {
  CVParserProcessSAWithPaginationDef,
  GetCVParserProcessSAParamsDef,
} from "@app/features/super-admin/types/super-admin.cv-parser.types";
import { MediaFile } from "@app/features/super-admin/types/super-admin.files.types";
import { queryKeysSA } from "./queryKeys";
import { superAdminApi } from "./super-admin.api";

export const startCVParserProcessSA = (workerProfileId: string, medias: MediaFile[]) => {
  return superAdminApi.post("/super_admin/cv_parser", {
    workerProfileId,
    medias,
  });
};

export const getCVParserProcessSA = (params: GetCVParserProcessSAParamsDef) => {
  return superAdminApi.get<CVParserProcessSAWithPaginationDef>("/super_admin/cv_parser", {
    params,
  });
};

export const useCVParserProcessSA = (
  params: GetCVParserProcessSAParamsDef,
  options?: {
    enabled?: boolean;
    refetchInterval?: number;
  }
) =>
  useQuery({
    enabled: options?.enabled ?? true,
    queryKey: [...queryKeysSA.lists(), "cv-parser-process", params] as const,
    queryFn: ({ queryKey }) => getCVParserProcessSA(queryKey[3]).then(({ data }) => data),
    refetchInterval: options?.refetchInterval,
  });
