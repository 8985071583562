import { UserOutlined } from "@ant-design/icons";
import { App, Avatar, Button, List, Modal, Skeleton } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { RoutePaths } from "@app/features/routes/types/routes.types";
import {
  CompanyAdminDef,
  CompanySADef,
  LoginAsAdminDef,
  LoginUnclaimCompanyDef,
} from "@app/features/super-admin/types/super-admin.company.types";
import { store } from "@app/store/store";

export const LoginAsCompanyButton = ({ company }: { company: CompanySADef }) => {
  const { t } = useTranslation();
  const app = App.useApp();
  const { dispatch } = store;
  const navigate = useNavigate();
  const [loginAsCompanyLoading, setLoginAsCompanyLoading] = useState(false);
  const [companyAdminsModalOpen, setCompanyAdminsModalOpen] = useState(false);
  const [selectedCompanyAdmins, setSelectCompanyAdmins] = useState<CompanyAdminDef[]>([]);
  const [selectedCompany, setSelectCompany] = useState<CompanySADef>();

  // Admins modal
  const handleAdminsModalCancel = () => {
    setCompanyAdminsModalOpen(false);
  };

  const loginAsCompany = async (payload: LoginAsAdminDef | LoginUnclaimCompanyDef) => {
    setLoginAsCompanyLoading(true);
    app.message.loading({
      content: "Logging in as company admin",
      key: "loginAsCompany",
      duration: 0,
    });
    try {
      await dispatch.superAdmin.loginCompanySA(payload);
      app.message.destroy("loginAsCompany");
      app.message.success({
        content: "Logged in successfully",
      });
      navigate(RoutePaths.DASHBOARD);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      {company.admins.length ? (
        company.admins.length === 1 ? (
          <Button
            type="link"
            disabled={loginAsCompanyLoading}
            onClick={() => {
              loginAsCompany(company.admins[0]);
            }}
          >
            {t("Log in")}
          </Button>
        ) : (
          <Button
            type="link"
            onClick={() => {
              setCompanyAdminsModalOpen(true);
              setSelectCompany(company);
              setSelectCompanyAdmins(company.admins as CompanyAdminDef[]);
            }}
          >
            {t("Admins")}
          </Button>
        )
      ) : (
        <Button
          type="link"
          disabled={loginAsCompanyLoading}
          onClick={() => {
            loginAsCompany({ targetCompanyId: company.id, controlCompany: true });
          }}
        >
          {t("Log in")}
        </Button>
      )}

      {companyAdminsModalOpen && (
        <Modal
          title={`${selectedCompany?.name} admins`}
          open={companyAdminsModalOpen}
          onCancel={handleAdminsModalCancel}
          footer={null}
        >
          <List
            itemLayout="horizontal"
            dataSource={selectedCompanyAdmins}
            loading={loginAsCompanyLoading}
            renderItem={(item: CompanyAdminDef) => (
              <List.Item
                actions={[
                  <Button
                    disabled={loginAsCompanyLoading}
                    onClick={() => {
                      loginAsCompany(item);
                    }}
                    key="list-login"
                  >
                    {t("Log in")}
                  </Button>,
                ]}
              >
                <Skeleton avatar title={false} loading={false} active>
                  <List.Item.Meta
                    avatar={
                      item.picture ? (
                        <Avatar src={item.picture} />
                      ) : (
                        <Avatar size="small" icon={<UserOutlined />} />
                      )
                    }
                    title={`${item.firstName} ${item.lastName}`}
                  />
                </Skeleton>
              </List.Item>
            )}
          />
        </Modal>
      )}
    </>
  );
};
