import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { useMutation } from "@tanstack/react-query";
import { Col, Divider, Form, Row, Switch, App, Spin, Alert } from "antd";
import { useTranslation } from "react-i18next";
import {
  updateUserSettingSA,
  useUserSettingSA,
} from "@app/features/super-admin/api/super-admin.user-setting";
import { UserFormDef } from "@app/features/super-admin/types/super-admin.user.types";
import { UserSettingsDef } from "@app/types/user-settings.types";

type UserOptInSettingsFormDef = Pick<UserSettingsDef, "subscribedToEmail" | "subscribedToWhatsapp">;

type UserOptInSettingsFormProps = {
  selectedUser?: UserFormDef;
};

export const UserOptInSettingsForm = ({ selectedUser }: UserOptInSettingsFormProps) => {
  const { t } = useTranslation();
  const { message } = App.useApp();
  const userSettingQuery = useUserSettingSA({ id: selectedUser?.id });

  const updateUserSettings = useMutation({
    mutationFn: updateUserSettingSA,
    onSuccess: () => {
      message.success({ content: t("Saved!"), key: "user-info" });
    },
    onSettled: () => {
      userSettingQuery.refetch();
    },
  });

  const onSubmitForm = async (_: unknown, values: UserOptInSettingsFormDef) => {
    if (selectedUser) {
      updateUserSettings.mutate({
        ...values,
        workerProfileId: selectedUser.id,
      });
    }
  };

  return (
    <>
      {userSettingQuery.isLoading && <Spin />}
      {userSettingQuery.error && <Alert type="error" message="Failed to load user setting data" />}
      {userSettingQuery.data && (
        <Form
          layout="vertical"
          initialValues={{
            subscribedToEmail: userSettingQuery.data.subscribedToEmail,
            subscribedToWhatsapp: userSettingQuery.data.subscribedToWhatsapp,
          }}
          onValuesChange={onSubmitForm}
          data-hs-do-not-collect="true"
        >
          <Row gutter={[12, 12]}>
            <Col span={24}>
              <Form.Item
                label="Subscribe to email notifications"
                name={"subscribedToEmail"}
                valuePropName="checked"
                help="When turned on, the user will receive emails"
              >
                <Switch checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} />
              </Form.Item>
            </Col>
            <Divider />
            <Col span={24}>
              <Form.Item
                label="Subscribe to WhatsApp notifications"
                name={"subscribedToWhatsapp"}
                valuePropName="checked"
                help="When turned on, the user will receive messages via WhatsApp"
              >
                <Switch checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      )}
    </>
  );
};
