import { Col, Form, Input } from "antd";
import _debounce from "lodash/debounce";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useGetCompanyIntegration } from "@app/api/company-integrations.api";
import { useIntegrationJobs } from "@app/api/integration-jobs.api";
import { JobAdFormSection } from "@app/components/pages/JobAdDetails/components/JobAdFormSection/JobAdFormSection";
import { SelectAutocomplete } from "@app/components/ui/SelectAutocomplete/SelectAutocomplete";
import { hasFeature } from "@app/helpers/integration.helper";
import { ECandidateStatusType } from "@app/types/candidate.types";
import { EKomboFeature } from "@app/types/company-integration.types";
import { JobAdFormDef } from "@app/types/job-ads.types";

export const JobAdCompanyIntegration = () => {
  const { t } = useTranslation();
  const form = Form.useFormInstance<JobAdFormDef>();
  const integrationJobAdIdRef = useRef<string | undefined>();
  const integrationJobAdId = Form.useWatch("integrationJobAdId", form);
  const integrationStatusMapId = Form.useWatch("integrationStatusMapId", form);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const { data: companyIntegration } = useGetCompanyIntegration();
  const { data: integrationJobs } = useIntegrationJobs({ searchQuery });
  const { data: currentJob } = useIntegrationJobs(
    { integrationJobAdId: integrationJobAdId?.toString() },
    { enabled: !!integrationJobAdId }
  );

  // CHOICES
  const uniqueJobsMap = new Map((integrationJobs || []).map((job) => [job.id, job]));
  if (currentJob && currentJob.length) {
    uniqueJobsMap.set(currentJob[0].id, currentJob[0]);
  }
  const integrationJobChoices =
    Array.from(uniqueJobsMap.values())?.map((i) => ({
      value: i.id,
      label: [
        i.name,
        i.location ? `- ${[i.location.city, i.location.country].filter(Boolean).join(", ")}` : null,
        i.job_code ? `(${i.job_code})` : null,
        !i.job_code && i.remote_id ? `(${i.remote_id})` : null,
      ]
        .filter(Boolean)
        .join(" "),
      id: `integration-job-${i.id}`,
    })) || [];
  const selectedIntegrationJob = integrationJobAdId
    ? currentJob?.find((i) => i.id === integrationJobAdId)
    : undefined;

  const integrationStatusMapChoices =
    selectedIntegrationJob?.stages.map((i) => ({
      value: i.id,
      label: i.name,
      id: `integration-status-${i.id}`,
    })) || [];

  const debouncedSearch = _debounce((value: string) => {
    setSearchQuery(value);
  }, 300);

  const onSearchIntegrationJobs = (value: string) => {
    debouncedSearch(value);
  };

  useEffect(() => {
    if (!integrationJobAdIdRef.current && integrationJobAdId) {
      integrationJobAdIdRef.current = integrationJobAdId;
    }
  }, [integrationJobAdId]);

  useEffect(() => {
    // Whenever the interview status has been mapped to the ATS interview stage
    // then add the stage data to the form
    if (integrationStatusMapId && selectedIntegrationJob?.stages) {
      const foundStage = selectedIntegrationJob.stages.find(
        (stage) => stage.id === integrationStatusMapId
      );
      if (foundStage) {
        form.setFieldsValue({
          integrationStatusMap: {
            integrationStageId: foundStage.id,
            integrationStageIndex: foundStage.index,
            integrationStageName: foundStage.name,
            integrationStageRemoteId: foundStage.remote_id,
            type: ECandidateStatusType.INTERVIEW,
          },
        });
      }
    } else if (!integrationStatusMapId) {
      form.setFieldsValue({
        integrationStatusMap: undefined,
      });
    }
  }, [integrationStatusMapId, selectedIntegrationJob?.stages]);

  if (!companyIntegration) {
    return null;
  }

  return (
    <JobAdFormSection title="integration-job-section">
      <Col span={24}>
        <Form.Item
          id="integration-job-select"
          label={t("integration-job-title")}
          name="integrationJobAdId"
          help={t("integration-job-help")}
          style={{ marginBottom: 32 }}
        >
          <SelectAutocomplete
            placeholder={t("Select")}
            options={integrationJobChoices}
            onSearch={onSearchIntegrationJobs}
            allowClear
            onSelect={(value) => {
              if (integrationJobAdIdRef.current !== value && value) {
                form.setFieldsValue({
                  integrationStatusMapId: undefined,
                  integrationStatusMap: undefined,
                });
                integrationJobAdIdRef.current = value;
              }
            }}
          />
        </Form.Item>
      </Col>
      {hasFeature(companyIntegration, EKomboFeature.JOB_SPECIFIC_APPLICATION_STAGES) && (
        <Col span={24}>
          <Form.Item
            id="integration-interview-status-select"
            label={t("integration-interview-status-title")}
            name="integrationStatusMapId"
            help={t("integration-interview-status-help")}
            style={{ marginBottom: 32 }}
          >
            <SelectAutocomplete
              placeholder={t("Select")}
              options={integrationStatusMapChoices}
              disabled={!integrationJobAdId}
              allowClear
            />
          </Form.Item>
          <Form.Item name="integrationStatusMap" hidden>
            <Input />
          </Form.Item>
        </Col>
      )}
    </JobAdFormSection>
  );
};
