import moment from "moment";
import { getTranslations } from "@app/api/translations.api";
import { isDev } from "@app/constants/env";
import { LATEST_LOCAL_FETCH } from "@app/constants/translations.constants";
import i18n, { staticTranslations } from "@app/translations/i18n";
import { WeblateProjects } from "@app/types/translations.types";

export function useLoadTranslations() {
  const loadProject = async (project: WeblateProjects) => {
    try {
      const lang = i18n.language;
      const lastFetchKey = `lastTranslationFetch-${project}-${lang}`;
      const savedDataKey = `${project}-${lang}.json`;
      const lastLocalFetch = moment.utc(LATEST_LOCAL_FETCH);
      const lastTranslationFetch = localStorage.getItem(lastFetchKey);
      let since = lastLocalFetch;

      // If we have more recent translations in local storage than in assets
      if (lastTranslationFetch && moment(lastTranslationFetch).isAfter(lastLocalFetch)) {
        since = moment(lastTranslationFetch);
        const savedTranslations = localStorage.getItem(savedDataKey);
        if (savedTranslations) {
          const staticData = isDev() ? staticTranslations[lang]?.translation : {};
          i18n.addResourceBundle(
            lang,
            "translation",
            {
              ...JSON.parse(savedTranslations),
              ...(typeof staticData === "string" ? {} : staticData),
            },
            true,
            true
          );
        }
      }

      const now = moment();
      const res = await getTranslations(project, lang, since.toDate());
      if (res.translations) {
        // We got more recent translations
        const staticData = isDev() ? staticTranslations[lang]?.translation : {};
        i18n.addResourceBundle(
          lang,
          "translation",
          {
            ...res.translations,
            ...(typeof staticData === "string" ? {} : staticData),
          },
          true,
          true
        );
        localStorage.setItem(savedDataKey, JSON.stringify(res.translations));
        localStorage.setItem(lastFetchKey, now.toISOString());
      }
    } catch (error) {
      console.error(error);
    }
  };

  const loadTranslations = () => {
    loadProject(WeblateProjects.ADMIN_PANEL);
    loadProject(WeblateProjects.SHARED);
  };

  return {
    loadTranslations,
  };
}
