import { useQuery } from "@tanstack/react-query";
import { CreateOrUpdateUserSettingsDef, UserSettingsDef } from "@app/types/user-settings.types";
import { superAdminApi } from "./super-admin.api";

const getUserSettingSA = async ({ id }: { id?: string }) => {
  const result = await superAdminApi.get<UserSettingsDef[]>("/worker_profiles/info_settings", {
    params: {
      workerProfileId: id,
    },
  });
  return result.data?.length ? result.data[0] : null;
};

export const useUserSettingSA = (params: { id?: string }) =>
  useQuery({
    enabled: Boolean(params.id),
    queryKey: ["user-setting-sa", params] as const,
    queryFn: ({ queryKey }) => getUserSettingSA(queryKey[1]),
  });

export const updateUserSettingSA = (
  payload: CreateOrUpdateUserSettingsDef & { workerProfileId: string }
) => {
  return superAdminApi.put<UserSettingsDef>("/worker_profiles/info_settings", payload);
};
