import { Badge, Col, Input, Row, Space, Switch, Table, TableColumnsType } from "antd";
import { TablePaginationConfig } from "antd/es/table/interface";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Label } from "@app/components/ui/Label/Label";
import { useCompaniesSA } from "@app/features/super-admin/api/super-admin.company.api";
import { SUPER_ADMIN_PAGE_SIZE } from "@app/features/super-admin/constants/super-admin.constants";
import {
  CompaniesSAColumnsDataDef,
  CompanySADef,
} from "@app/features/super-admin/types/super-admin.company.types";
import { ApprovalStatusEnum } from "@app/types/company-info.types";
import { useCompanyModal } from "./components/CompanyModal";
import { LoginAsCompanyButton } from "./components/LoginAsCompanyButton";

const { Search } = Input;

export const Companies = () => {
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  // table params
  const [keyword, setKeyword] = useState("");
  const [onlyShowPaying, setOnlyShowPaying] = useState(false);
  const [onlyShowClaimed, setOnlyShowClaimed] = useState(true);
  // modals
  const { setCompanyId } = useCompanyModal();
  // data

  const companiesQuery = useCompaniesSA({
    keyword,
    isPaying: onlyShowPaying || undefined,
    isClaimed: onlyShowClaimed || undefined,
    limit: SUPER_ADMIN_PAGE_SIZE,
    offset: page,
  });

  const handlePageChange = async (pagination: TablePaginationConfig) => {
    if (typeof pagination.current === "number" && typeof pagination.pageSize === "number") {
      setPage(pagination.current - 1);
    }
  };

  const getApprovalStatusBadge = (status: ApprovalStatusEnum) => {
    switch (status) {
      case ApprovalStatusEnum.APPROVED:
        return "success";
      case ApprovalStatusEnum.NOT_STARTED:
        return "default";
      case ApprovalStatusEnum.PENDING:
        return "processing";
      case ApprovalStatusEnum.REJECTED:
        return "error";
    }
  };

  const generatedData = (data: CompanySADef[]): CompaniesSAColumnsDataDef[] => {
    return data.map((item) => ({
      key: item.id,
      name: <a onClick={() => setCompanyId(item.id)}>{item.name}</a>,
      contactEmail: item.contactEmail,
      contactPhone: item.contactPhone,
      approvalStatus: (
        <Badge
          key="approvalStatus"
          status={getApprovalStatusBadge(item.approvalStatus)}
          text={item.approvalStatus}
        />
      ),
      hasPaidForJobAds: (
        <Badge
          key="hasPaidForJobAds"
          status={item.hasPaidForJobAds ? "success" : "default"}
          text={t(item.hasPaidForJobAds ? "paid" : "not paid")}
        />
      ),
      internalComment: item.internalComment,
      operation: (
        <Space size="middle">
          <a
            onClick={() => {
              setCompanyId(item.id);
            }}
          >
            {t("Settings")}
          </a>
          <LoginAsCompanyButton company={item} />
        </Space>
      ),
    }));
  };

  const columns: TableColumnsType<CompaniesSAColumnsDataDef> = [
    { title: t("Company name"), dataIndex: "name", key: "title" },
    { title: t("Email"), dataIndex: "contactEmail", key: "contactEmail", width: 200 },
    { title: t("Mobile phone"), dataIndex: "contactPhone", key: "contactPhone", width: 150 },
    { title: t("Approval status"), dataIndex: "approvalStatus", key: "approvalStatus", width: 180 },
    {
      title: t("Has paid for job ads"),
      dataIndex: "hasPaidForJobAds",
      key: "hasPaidForJobAds",
      width: 150,
    },
    {
      title: t("Comment"),
      dataIndex: "internalComment",
      key: "internalComment",
      ellipsis: true,
      width: 150,
    },
    { title: t("Actions"), dataIndex: "operation", key: "operation", fixed: "right", width: 200 },
  ];

  return (
    <>
      <Space direction="vertical" size="large" style={{ width: "100%" }}>
        <Search
          placeholder={t("Search for company id, company name or contact email")}
          enterButton={t("Search")}
          size="large"
          onSearch={(value) => {
            setPage(0);
            setKeyword(value);
          }}
          allowClear
        />
        <Row>
          <Space size="large">
            <Col>
              <Label htmlFor="onlyPaying">{t("Has paid for job ads")}</Label>
              <Switch
                id="onlyPaying"
                checked={onlyShowPaying}
                onChange={() => {
                  setPage(0);
                  setOnlyShowPaying(!onlyShowPaying);
                }}
              />
            </Col>
            <Col>
              <Label htmlFor="onlyClaimed">{t("Exclude scraped companies")}</Label>
              <Switch
                id="onlyClaimed"
                checked={onlyShowClaimed}
                onChange={() => {
                  setPage(0);
                  setOnlyShowClaimed(!onlyShowClaimed);
                }}
              />
            </Col>
          </Space>
        </Row>
        <Table
          style={{ width: "100%" }}
          columns={columns}
          dataSource={generatedData(companiesQuery.data?.data ?? [])}
          loading={companiesQuery.isLoading}
          pagination={{
            current: page + 1,
            total: companiesQuery.data?.count,
            showTotal: (total) => `${t("Total")} ${total} ${t("Companies")}`,
            pageSize: SUPER_ADMIN_PAGE_SIZE,
            showSizeChanger: false,
            position: ["bottomLeft"],
          }}
          onChange={handlePageChange}
          scroll={{ x: 1250 }}
        />
      </Space>
    </>
  );
};
