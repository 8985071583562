import { App } from "antd";
import { useTranslation } from "react-i18next";

const MESSAGE_KEY = "useCopy-message";

export const useCopy = ({
  successMessage = "Link copied!",
  errorMessage = "Could not copy link",
} = {}) => {
  const { message, modal } = App.useApp();
  const { t } = useTranslation();

  return {
    copyToClipboard: async (value: string) => {
      try {
        message.destroy(MESSAGE_KEY);
        await navigator.clipboard.writeText(value);
        message.success({ content: t(successMessage), key: MESSAGE_KEY });
      } catch {
        modal.error({
          title: errorMessage,
          content: value,
        });
        message.destroy(MESSAGE_KEY);
      }
    },
  };
};
