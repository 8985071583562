import { Flex, Select, SelectProps } from "antd";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { HiBellAlert, HiBellSlash } from "react-icons/hi2";
import { EJobAvailability } from "@app/features/super-admin/types/super-admin.user.types";
import styles from "./UserJobAvailability.module.scss";

type UserJobAvailabilityProps = Omit<SelectProps, "options" | "popupMatchSelectWidth" | "disabled">;

const MenuOption = ({
  icon,
  className,
  label,
}: {
  icon: JSX.Element;
  className: string;
  label: string;
}) => {
  return (
    <Flex gap="small" align="center">
      <div className={clsx(styles.icon, className)}>{icon}</div>
      {label}
    </Flex>
  );
};

export const UserJobAvailability = ({ style, ...rest }: UserJobAvailabilityProps) => {
  const { t } = useTranslation();

  const options = [
    {
      label: (
        <MenuOption
          icon={<HiBellAlert />}
          label={t("job-availability-open-to-work")}
          className={styles.open}
        />
      ),
      value: EJobAvailability.OPEN,
    },
    {
      label: (
        <MenuOption
          icon={<HiBellSlash />}
          label={t("job-availability-closed-to-offers")}
          className={styles.closed}
        />
      ),
      value: EJobAvailability.CLOSED,
    },
  ];

  return (
    <Select
      style={{
        width: "100%",
        maxWidth: "350px",
        ...style,
      }}
      options={options}
      popupMatchSelectWidth={false}
      {...rest}
      title="DANGER - turning this off will stop the user from receiving scheduled job recommendations per email/phone/whatsapp."
    />
  );
};
