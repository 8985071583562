import confetti from "canvas-confetti";
import sample from "lodash/sample";

export const launchConfetti = (x: number, y: number) => {
  const scalar = 2;
  const coolEmojis = ["🎉", "😎", "🥳", "🎈", "🦄", "🔥", "🏆", "🌟", "✨", "👍", "🆒"];

  const defaults = {
    spread: 360,
    ticks: 75,
    gravity: 0,
    decay: 0.96,
    startVelocity: 20,
    shapes: [confetti.shapeFromText({ text: sample(coolEmojis) ?? "", scalar })],
    scalar,
  };

  function shoot() {
    confetti({
      ...defaults,
      particleCount: 30,
      origin: { x, y },
    });

    confetti({
      ...defaults,
      particleCount: 5,
      flat: true,
      origin: { x, y },
    });

    confetti({
      ...defaults,
      particleCount: 15,
      scalar: scalar / 2,
      shapes: ["circle"],
      origin: { x, y },
    });
  }

  setTimeout(shoot, 0);
  setTimeout(shoot, 100);
  setTimeout(shoot, 200);
};
