export interface LocalLoginForm {
  email: string;
  password: string;
}

export interface LocalSignupForm {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  password: string;
  passwordConfirm: string;
  name: string;
}
export interface LocalClaimForm extends LocalSignupForm {
  companyId: string;
  nonce: string;
}

export interface LocalForgatPasswordForm {
  email: string;
}

export interface ICompanySignupPayload {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  name: string;
  lang: string;
  utmParams?: object;
}
export interface ICompanyClaimPayload {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  companyId: string;
  nonce: string;
  lang: string;
}

export interface ChangePasswordForm {
  newPassword: string;
  newPasswordConfirm: string;
}
export interface IPasswordChangePayload {
  newPassword: string;
}

export enum EUserTeam {
  TALENT_MANAGEMENT_TEAM = "talent-management-team",
}

export enum EUserRole {
  USER = "user",
  COMPANY_ADMIN = "company-admin",
  SUPER_ADMIN = "super-admin",
}

export type AuthUserDef = {
  id: string;
  firstName: string;
  lastName: string;
  email: string | null;
  phone: string | null;
  picture: string | null;
  team?: EUserTeam[] | null;
  role?: EUserRole;
};

export interface IAuthLoginResponse {
  accessToken: string;
  workerProfile: AuthUserDef;
}

export interface IVerificationWorkerProfileResponse {
  message: string;
}

export interface ISignupWorkerProfileResponse {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string | null;
  picture: string;
}

export type AuthTokenDef = {
  id: string;
  firstName: string;
  lastName: string;
  email: string | null;
  phone: string | null;
  company: {
    id: string;
    name: string;
  };
  isCrafthuntCompanyAdmin: boolean;
  isSuperAdmin: boolean;
  isInternal: boolean;
  isInternalSwitching: boolean;
  iat: number;
  exp: number;
  aud: "https://crafthunt.app";
  iss: "crafthunt";
  jti: string;
};
