import {
  ApiCompanyInfoResponse,
  CompanyBioInputType,
  CompanyInfoType,
  GenerateCompanyBioResponse,
  UpdateApprovalStatus,
} from "@app/types/company-info.types";
import { api } from "./api";

export const getCompanyInfo = async (
  id: string
): Promise<{ company: CompanyInfoType; slug: string }> => {
  const response = await api.get<ApiCompanyInfoResponse>(`/crafthunts/company_resources/${id}`);
  return {
    company: {
      id: response.data.id,
      name: response.data.name,
      description: response.data.description,
      lang: response.data.lang,
      address: {
        country: response.data.address?.country || "",
        city: response.data.address?.city || "",
        street: response.data.address?.street || "",
        postalCode: response.data.address?.postalCode || "",
      },
      coordinates: {
        lat: Number(response.data.coordinate?.lat || 0),
        lng: Number(response.data.coordinate?.lng || 0),
      },
      logo: response.data.image || null,
      coverImage: response.data.coverImage || null,
      approvalStatus: response.data.approvalStatus,
      hasPaidForJobAds: response.data.hasPaidForJobAds,
      hasIntegrations: response.data.hasIntegrations,
      companyIndustriesId: response.data.companyIndustriesId || [],
      enabledAdvertisements: response.data.enabledAdvertisements || [],
    },
    slug: response.data.slug,
  };
};

export const putCompanyInfo = async (payload: {
  info: CompanyInfoType;
}): Promise<CompanyInfoType> => {
  const response = await api.put<ApiCompanyInfoResponse>(
    `/crafthunts/company_resources/${payload.info.id}`,
    {
      name: payload.info.name,
      logo: payload.info.logo,
      lang: payload.info.lang,
      description: payload.info.description,
      coverImage: payload.info.coverImage,
      address: payload.info.address,
      coordinate: {
        geoJsonType: "Point",
        lat: Number(payload.info.coordinates.lat),
        lng: Number(payload.info.coordinates.lng),
      },
      companyIndustriesId: payload.info.companyIndustriesId || [],
      enabledAdvertisements: payload.info.enabledAdvertisements || undefined,
    }
  );
  return {
    id: response.data.id,
    name: response.data.name,
    description: response.data.description,
    lang: response.data.lang,
    address: {
      country: response.data.address?.country || "",
      city: response.data.address?.city || "",
      street: response.data.address?.street || "",
      postalCode: response.data.address?.postalCode || "",
    },
    coordinates: {
      lat: Number(response.data.coordinate?.lat || 0),
      lng: Number(response.data.coordinate?.lng || 0),
    },
    logo: response.data.image || null,
    coverImage: response.data.coverImage || null,
    approvalStatus: response.data.approvalStatus,
    hasPaidForJobAds: response.data.hasPaidForJobAds,
    hasIntegrations: response.data.hasIntegrations,
    companyIndustriesId: response.data.companyIndustriesId || [],
    enabledAdvertisements: response.data.enabledAdvertisements || [],
  };
};

export const updateApprovalStatus = async (
  payload: UpdateApprovalStatus
): Promise<CompanyInfoType> => {
  const response = await api.put<ApiCompanyInfoResponse>(
    `/crafthunts/company_resources/${payload.id}`,
    {
      approvalStatus: payload.approvalStatus,
    }
  );
  return {
    id: response.data.id,
    name: response.data.name,
    description: response.data.description,
    lang: response.data.lang,
    address: {
      country: response.data.address?.country || "",
      city: response.data.address?.city || "",
      street: response.data.address?.street || "",
      postalCode: response.data.address?.postalCode || "",
    },
    coordinates: {
      lat: Number(response.data.coordinate?.lat || 0),
      lng: Number(response.data.coordinate?.lng || 0),
    },
    logo: response.data.image || null,
    coverImage: response.data.coverImage || null,
    approvalStatus: response.data.approvalStatus,
    hasPaidForJobAds: response.data.hasPaidForJobAds,
    hasIntegrations: response.data.hasIntegrations,
    companyIndustriesId: response.data.companyIndustriesId || [],
    enabledAdvertisements: response.data.enabledAdvertisements || [],
  };
};

export const generateCompanyBio = async (payload: CompanyBioInputType): Promise<string> => {
  const response = await api.post<GenerateCompanyBioResponse>(`/crafthunts/generate_bio`, payload);
  return response.data.data;
};
