import { Button, Col, Input, Popover, Row, Space, Table, TableColumnsType, Tag } from "antd";
import { FilterValue, SorterResult, TablePaginationConfig } from "antd/es/table/interface";
import { useState } from "react";
import isEqual from "react-fast-compare";
import { useTranslation } from "react-i18next";
import { StatusFilter } from "@app/components/pages/JobAds/StatusFilter/StatusFilter";
import { AddressPicker, EAddressPickerType } from "@app/components/ui/AddressPicker/AddressPicker";
import { StatusBadge } from "@app/components/ui/StatusBadge/StatusBadge";
import { useJobsSA } from "@app/features/super-admin/api/super-admin.job.api";
import { useCompanyModal } from "@app/features/super-admin/components/SuperAdminStuff/components/Companies/components/CompanyModal";
import { CompanyFilter } from "@app/features/super-admin/components/SuperAdminStuff/components/Filters/CompanyFilter/CompanyFilter";
import { RolesFilter } from "@app/features/super-admin/components/SuperAdminStuff/components/Filters/RolesFilter/RolesFilter";
import { TradesFilter } from "@app/features/super-admin/components/SuperAdminStuff/components/Filters/TradesFilter/TradesFilter";
import { SUPER_ADMIN_PAGE_SIZE } from "@app/features/super-admin/constants/super-admin.constants";
import { JobAdSADef } from "@app/features/super-admin/types/super-admin.job.types";
import { UserFormDef } from "@app/features/super-admin/types/super-admin.user.types";
import { getTradeName } from "@app/helpers/tag.helper";
import { AddressObjDef } from "@app/types/address.types";
import { EJobAdStatus } from "@app/types/job-ads.types";
import { OrderByDef } from "@app/types/pagination.types";
import { useJobModal } from "./JobModal/JobModal";

const { Search } = Input;

export const Jobs = ({
  selectedUser,
  selectedJobs,
  RecommendComponent,
  onJobSelectChange,
}: {
  selectedUser?: UserFormDef;
  selectedJobs?: JobAdSADef[];
  RecommendComponent?: React.FC<{
    job: JobAdSADef;
    selectedUser: UserFormDef;
    companyId: string;
    selectedJobs: JobAdSADef[];
    onJobSelectChange?: (selectedJobs: JobAdSADef[]) => void;
  }>;
  onJobSelectChange?: (selectedJobs: JobAdSADef[]) => void;
}) => {
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const [orderBy, setOrderBy] = useState<OrderByDef[]>([]);
  const { setCompanyId } = useCompanyModal();
  const { setJobId } = useJobModal();
  // filter params
  const [keyword, setKeyword] = useState("");
  const [status, setStatus] = useState();
  const [selectedTradeIds, setSelectedTradeIds] = useState([]);
  const [selectedJobTitleIds, setSelectedJobTitleIds] = useState([]);
  const [selectedCoordinates, setSelectedCoordinates] = useState("");
  const [companyIdForFilter, setCompanyIdForFilter] = useState<string>();

  const jobsQuery = useJobsSA({
    keyword,
    status,
    tradeIds: selectedTradeIds,
    jobTitleIds: selectedJobTitleIds,
    coordinates: selectedCoordinates,
    companyId: companyIdForFilter,
    limit: SUPER_ADMIN_PAGE_SIZE,
    offset: page,
    orderBy,
    ...(selectedUser?.id && {
      workerProfileId: selectedUser.id,
      showAllJobs: true,
    }),
  });

  const handleAddressChange = (address: AddressObjDef | null) => {
    if (address?.coordinates) {
      setSelectedCoordinates(`${address.coordinates.lat},${address.coordinates.lng}`);
    } else {
      setSelectedCoordinates("");
    }
  };

  const handlePageChange = async (
    pagination: TablePaginationConfig,
    _filters: Record<string, FilterValue | null>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    sorter: SorterResult<any> | SorterResult<any>[]
  ) => {
    if (typeof pagination.current === "number" && typeof pagination.pageSize === "number") {
      setPage(pagination.current - 1);
    }
    if (sorter) {
      const sorts = Array.isArray(sorter) ? sorter : [sorter];
      const newOrderBy = sorts
        .filter((sort) => sort.field != null)
        .map((sort) => ({
          fieldName: sort.field?.toString(),
          order: sort.order === "ascend" ? "ASC" : "DESC",
        }));
      if (!isEqual(newOrderBy, orderBy)) {
        setOrderBy(newOrderBy as OrderByDef[]);
        setPage(0);
      }
    }
  };

  const generatedData = (data: JobAdSADef[]) => {
    return data?.map((item) => {
      const distance =
        typeof item.distance === "number"
          ? item.distance
          : item.matchScoreMetadata?.distanceKm || null;

      return {
        key: item.id,
        status: (
          <StatusBadge status={t(item.status)} isSuccess={item.status === EJobAdStatus.PUBLISHED} />
        ),
        jobName: <a onClick={() => setJobId(item.id)}>{item.title}</a>,
        isScrapedJob: (
          <Tag color={item.crafthuntCompany.isClaimed ? "green" : "red"}>
            {item.crafthuntCompany.isClaimed ? t("No") : t("Yes")}
          </Tag>
        ),
        companyName: (
          <a onClick={() => setCompanyId(item.crafthuntCompany.id)}>{item.crafthuntCompany.name}</a>
        ),
        role: item.role ? t(item.role.name) : "",
        trade: item.trade ? t(getTradeName(item.trade.name, item.role?.category)) : "",
        location: item.address
          ? (item.address.countryCode === "DE" && item.address.city) ||
            [item.address.city, item.address.country].filter(Boolean).join(", ")
          : "",
        distance: distance == null ? "-" : `${distance.toFixed(1).replace(".0", "")} km`,
        selectionData: {
          id: item.id,
          jobName: item.title,
          companyName: item.crafthuntCompany.name,
        },
        ...(selectedUser && {
          score: (
            <Popover
              content={<pre>{JSON.stringify(item.matchScoreMetadata, null, 2)}</pre>}
              trigger="click"
            >
              <Button type="text">{`${Math.ceil((item.score || 0) * 100)}%`}</Button>
            </Popover>
          ),
        }),
        ...(selectedUser &&
          RecommendComponent &&
          selectedJobs && {
            operation: (
              <RecommendComponent
                selectedUser={selectedUser}
                job={item}
                selectedJobs={selectedJobs}
                onJobSelectChange={onJobSelectChange}
                companyId={item.crafthuntCompany.id}
              />
            ),
          }),
      };
    });
  };

  const columns: TableColumnsType<ReturnType<typeof generatedData>[number]> = [
    { title: null, dataIndex: "status", key: "status", width: 25 },
    {
      title: "Scraped",
      dataIndex: "isScrapedJob",
      key: "isScrapedJob",
      ellipsis: true,
      width: 100,
    },
    { title: t("Job title"), dataIndex: "jobName", key: "jobName", ellipsis: true },
    {
      title: t("Company name"),
      dataIndex: "companyName",
      key: "companyName",
      ellipsis: true,
      width: 250,
    },
    { title: t("Role"), dataIndex: "role", key: "role", ellipsis: true, width: 150 },
    { title: t("Trade"), dataIndex: "trade", key: "trade", ellipsis: true, width: 150 },
    { title: t("Address"), dataIndex: "location", key: "location", ellipsis: true, width: 200 },
    {
      title: t("distance-label"),
      dataIndex: "distance",
      key: "distance",
      ellipsis: true,
      width: 125,
    },
    ...(selectedUser
      ? [
          {
            title: "Score",
            dataIndex: "score",
            key: "score",
            width: 100,
            sorter: true,
          },
          {
            title: "Recommend",
            dataIndex: "operation",
            key: "operation",
            fixed: "right" as const,
            width: 200,
          },
        ]
      : []),
  ];

  return (
    <Space direction="vertical" size="large" style={{ width: "100%" }}>
      <Row gutter={[16, 16]} align="middle">
        <Col span={12}>
          <Search
            placeholder={t("job-ads-search-placeholder")}
            enterButton={t("Search")}
            size="large"
            onSearch={(value) => {
              setPage(0);
              setKeyword(value);
            }}
            allowClear
          />
        </Col>
        <Col span={12}>
          <CompanyFilter
            onChange={(v) => {
              setPage(0);
              setCompanyIdForFilter(v);
            }}
          />
        </Col>
      </Row>
      <Row gutter={[16, 16]} align="middle">
        <Col span={6}>
          <AddressPicker
            type={EAddressPickerType.CITY}
            disableMap
            onChange={(newAddress) => {
              setPage(0);
              handleAddressChange(newAddress);
            }}
          />
        </Col>
        <Col span={6}>
          <RolesFilter
            onChange={(value) => {
              setPage(0);
              setSelectedJobTitleIds(value);
            }}
          />
        </Col>
        <Col span={6}>
          <TradesFilter
            onChange={(value) => {
              setPage(0);
              setSelectedTradeIds(value);
            }}
          />
        </Col>
        <Col span={6}>
          <StatusFilter
            onChange={(value) => {
              setPage(0);
              setStatus(value);
            }}
          />
        </Col>
      </Row>
      <Table
        style={{ width: "100%" }}
        columns={columns}
        dataSource={generatedData(jobsQuery.data?.data ?? [])}
        loading={jobsQuery.isLoading}
        pagination={{
          current: page + 1,
          total: jobsQuery.data?.count,
          showTotal: (total) => `${t("Total")} ${total} ${t("Job ads")}`,
          pageSize: SUPER_ADMIN_PAGE_SIZE,
          showSizeChanger: false,
          position: ["bottomLeft"],
        }}
        onChange={handlePageChange}
        scroll={{ x: selectedUser ? 1500 : 1400 }}
      />
    </Space>
  );
};
