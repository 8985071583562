import { DeleteOutlined } from "@ant-design/icons";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  App,
  Button,
  Card,
  List,
  message,
  Space,
  Spin,
  Tabs,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import moment from "moment";
import { useState } from "react";
import { DateFormats } from "@app/constants/date.constants";
import {
  deleteJobRecommendationSA,
  engagementQueryKey,
  recommendJobsSA,
  useEngagementSA,
} from "@app/features/super-admin/api/super-admin.engagement.api";
import { Jobs } from "@app/features/super-admin/components/SuperAdminStuff/components/Jobs/Jobs";
import { EEngagementType } from "@app/features/super-admin/types/super-admin.engagement.types";
import { JobAdSADef } from "@app/features/super-admin/types/super-admin.job.types";
import { UserFormDef } from "@app/features/super-admin/types/super-admin.user.types";
import { RecommendInternalNoteModal } from "./components/RecommendInternalNoteModal";

type Props = {
  selectedUser: UserFormDef;
  renderInline?: boolean;
};

enum TAB_KEYS {
  COMPANY = "company",
  JOB = "job",
}

const jobLimit = 3;

export const RecommendationsTab = ({ renderInline, selectedUser }: Props) => {
  const queryClient = useQueryClient();
  const app = App.useApp();
  const [activeTab, setActiveTab] = useState(TAB_KEYS.COMPANY);
  const [selectedJobs, setSelectedJobs] = useState<JobAdSADef[]>([]);

  const recommendJobs = useMutation({
    mutationFn: recommendJobsSA,
    onSuccess: () => {
      setSelectedJobs([]);
      message.success("Jobs have been recommended!");
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: engagementQueryKey,
      });
    },
  });

  return (
    <>
      <Tabs
        size="small"
        activeKey={activeTab}
        onChange={(tabKey) => setActiveTab(tabKey as TAB_KEYS)}
        style={{ width: "100%", flex: 1 }}
        items={[
          {
            key: TAB_KEYS.COMPANY,
            label: "Recommend user to company",
            children: null,
          },
          {
            key: TAB_KEYS.JOB,
            label: "Recommend job to user",
            children: null,
          },
        ]}
      />
      <Jobs
        selectedUser={selectedUser}
        selectedJobs={selectedJobs}
        RecommendComponent={RecommendedStatus}
        onJobSelectChange={
          activeTab === TAB_KEYS.JOB
            ? (items) => {
                if (items.length > jobLimit) {
                  message.warning("You can only select up to 3 jobs");
                }
                setSelectedJobs(items.slice(0, 3));
              }
            : undefined
        }
      />
      {activeTab === TAB_KEYS.JOB && (
        <div
          style={{
            position: "sticky",
            bottom: renderInline ? 0 : "40px",
            display: "flex",
            justifyContent: "center",
            pointerEvents: "none",
            zIndex: 10,
          }}
        >
          <Card
            size="small"
            style={{
              pointerEvents: "auto",
            }}
          >
            <Space direction="vertical" size="small" align="center">
              <Typography.Text
                type="secondary"
                style={{
                  fontSize: "14px",
                }}
              >
                Select up to {jobLimit} jobs to recommend to the candidate
              </Typography.Text>
              <Space>
                <Button
                  type="default"
                  onClick={() => setSelectedJobs([])}
                  disabled={selectedJobs.length === 0}
                >
                  Clear
                </Button>
                <Button
                  type="primary"
                  disabled={selectedJobs.length === 0}
                  loading={recommendJobs.isPending}
                  onClick={() => {
                    app.modal.confirm({
                      okText: "Recommend",
                      title: "Are you sure you want to recommend these jobs to the candidate?",
                      content: (
                        <List
                          bordered
                          itemLayout="horizontal"
                          size="small"
                          dataSource={selectedJobs}
                          renderItem={(item) => (
                            <List.Item>
                              <List.Item.Meta
                                title={item.title}
                                description={item.crafthuntCompany.name}
                              />
                            </List.Item>
                          )}
                        />
                      ),
                      onOk: () => {
                        recommendJobs.mutate({
                          workerProfileId: selectedUser.id,
                          engagementType: "manually-recommended-to-user",
                          jobAdIds: selectedJobs.map((item) => item.id),
                        });
                      },
                    });
                  }}
                >
                  Send jobs to the candidate ({selectedJobs.length})
                </Button>
              </Space>
            </Space>
          </Card>
        </div>
      )}
    </>
  );
};

const RecommendedStatus = ({
  job,
  selectedUser,
  companyId,
  selectedJobs,
  onJobSelectChange,
}: {
  job: JobAdSADef;
  selectedUser: UserFormDef;
  companyId: string;
  selectedJobs: JobAdSADef[];
  onJobSelectChange?: (items: JobAdSADef[]) => void;
}) => {
  const [showInternalNoteModal, setShowInternalNoteModal] = useState(false);
  const queryClient = useQueryClient();
  const engagementQuery = useEngagementSA(
    !onJobSelectChange && companyId
      ? {
          workerProfileId: selectedUser.id,
          companyId,
        }
      : {
          workerProfileId: selectedUser.id,
          jobAdId: job.id,
        }
  );

  const recommendJobs = useMutation({
    mutationFn: recommendJobsSA,
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: engagementQueryKey,
      });
    },
  });

  const deleteRecommendation = useMutation({
    mutationFn: deleteJobRecommendationSA,
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: engagementQueryKey,
      });
    },
  });

  const isAllowedToRecommend =
    !job.requiredCountries ||
    job.requiredCountries.length === 0 ||
    !selectedUser.address?.address?.countryCode ||
    job.requiredCountries.some(
      (country) => country.value === selectedUser.address?.address?.countryCode
    );

  const isSelected = selectedJobs.some((item) => item.id === job.id);

  return (
    <>
      {engagementQuery.isLoading ? (
        <Spin />
      ) : (
        <Space
          size="small"
          direction="vertical"
          style={{
            maxWidth: "100%",
          }}
        >
          {engagementQuery.data && (
            <>
              <Tooltip title={engagementQuery.data.engagementType}>
                <Tag
                  style={{
                    display: "block",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    maxWidth: "100%",
                    width: "max-content",
                    margin: 0,
                  }}
                >
                  {engagementQuery.data.engagementType}
                </Tag>
              </Tooltip>

              {engagementQuery.data.updatedAt && (
                <Typography.Text
                  type="secondary"
                  style={{
                    display: "block",
                    fontSize: "12px",
                    lineHeight: 1,
                  }}
                >
                  {moment(engagementQuery.data.updatedAt).format(DateFormats.FULL_DATE_TIME)}
                </Typography.Text>
              )}
            </>
          )}
          {onJobSelectChange ? (
            <>
              {!engagementQuery.data && (
                <Button
                  type={isSelected ? "primary" : "default"}
                  size="small"
                  style={{
                    lineHeight: 1,
                  }}
                  onClick={() => {
                    onJobSelectChange(
                      isSelected
                        ? selectedJobs.filter((item) => item.id !== job.id)
                        : [...selectedJobs, job]
                    );
                  }}
                >
                  {isSelected ? "Selected" : "Select"}
                </Button>
              )}
            </>
          ) : (
            <>
              {engagementQuery.data &&
                engagementQuery.data.engagementType === EEngagementType.MANUALLY_RECOMMENDED && (
                  <Button
                    icon={<DeleteOutlined />}
                    disabled={deleteRecommendation.isPending}
                    onClick={() => {
                      deleteRecommendation.mutate({
                        companyId,
                        workerProfileId: selectedUser.id,
                      });
                    }}
                  />
                )}
              {!engagementQuery.data && (
                <Tooltip
                  title={
                    isAllowedToRecommend ? undefined : "Job country and user country do not match"
                  }
                >
                  <Button
                    size="small"
                    type="primary"
                    style={{
                      lineHeight: 1,
                    }}
                    disabled={!isAllowedToRecommend || recommendJobs.isPending}
                    onClick={() => {
                      setShowInternalNoteModal(true);
                    }}
                  >
                    Recommend
                  </Button>
                </Tooltip>
              )}
            </>
          )}
        </Space>
      )}
      <RecommendInternalNoteModal
        open={showInternalNoteModal}
        onCancel={() => setShowInternalNoteModal(false)}
        onRecommend={(internalNote) => {
          setShowInternalNoteModal(false);
          recommendJobs.mutate({
            workerProfileId: selectedUser.id,
            engagementType: "manually-recommended",
            jobAdIds: [job.id],
            internalNote,
          });
        }}
      />
    </>
  );
};
