import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Divider, Form, Row, Switch } from "antd";
import { useTranslation } from "react-i18next";

export const CompanyAdvancedForm = () => {
  const { t } = useTranslation();

  return (
    <Row gutter={[12, 12]}>
      <Form.Item
        label={t("is-test-company-toggle")}
        name={"isTestCompany"}
        valuePropName="checked"
        help={t("is-test-company-help")}
      >
        <Switch checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} />
      </Form.Item>
      <Divider />
      <Form.Item
        label={t("is-claimed-toggle")}
        name={"isClaimed"}
        valuePropName="checked"
        help={t("is-claimed-help")}
      >
        <Switch checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} />
      </Form.Item>
    </Row>
  );
};
