import { Badge, Tooltip } from "antd";
import styles from "./StatusBadge.module.scss";

type StatusBadgeProps = {
  status?: string;
  isSuccess: boolean;
};

export const StatusBadge = ({ status, isSuccess }: StatusBadgeProps) => {
  const badge = (
    <Badge key="status" status={isSuccess ? "success" : "warning"} className={styles.badge} />
  );

  if (!status) {
    return badge;
  }

  return <Tooltip title={status}>{badge}</Tooltip>;
};
