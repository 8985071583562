import { App, Col, Form, InputNumber, Row } from "antd";
import { useForm } from "antd/lib/form/Form";
import axios from "axios";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@app/components/ui/Button/Button";
import { LoadingSpinner } from "@app/components/ui/LoadingSpinner/LoadingSpinner";
import { SelectAutocomplete } from "@app/components/ui/SelectAutocomplete/SelectAutocomplete";
import { SPOKEN_LANGUAGES } from "@app/constants/stat.constants";
import {
  editUserStatsSA,
  useUserStatsSA,
} from "@app/features/super-admin/api/super-admin.user.api";
import {
  mapStatsToApi,
  mapStatsToForm,
} from "@app/features/super-admin/helpers/super-admin.helper";
import {
  ALL_STATS_WITH_NUMBERS,
  ECraftsmanStatTypes,
  EProfileTravelType,
  StatUnitMap,
  UserFormDef,
  UserStatsFormDef,
} from "@app/features/super-admin/types/super-admin.user.types";
import { ApiFormErrorDef, getErrorMessages } from "@app/helpers/error.helper";
import { convertLangForBackend, getLangName } from "@app/helpers/language.helper";

type UserStatsProps = {
  onChangedValues: (changed: boolean) => void;
  selectedUser?: UserFormDef;
};

export const UserStats = ({ onChangedValues, selectedUser }: UserStatsProps) => {
  const { t, i18n } = useTranslation();
  const { message } = App.useApp();
  const [loadingSave, setLoadingSave] = useState(false);
  const [form] = useForm<UserStatsFormDef>();

  const statsQuery = useUserStatsSA({
    workerProfileId: selectedUser?.id,
  });

  const statsId = statsQuery.data?.id;

  useEffect(() => {
    if (statsQuery.isSuccess) {
      onChangedValues(false);
      form.setFieldsValue(mapStatsToForm(statsQuery.data.data));
    }
  }, [statsQuery.data]);

  const onSubmitForm = async (values: UserStatsFormDef) => {
    if (statsId && selectedUser) {
      setLoadingSave(true);
      try {
        await editUserStatsSA({
          ...mapStatsToApi(values),
          id: statsId,
          profileId: selectedUser.id,
        });
        onChangedValues(false);
        message.success({ content: t("Saved!"), key: "user-stats", duration: 1 });
      } catch (error) {
        if (axios.isAxiosError(error)) {
          message.error({
            content: getErrorMessages(error as ApiFormErrorDef),
            key: "user-stats",
            duration: 10,
          });
        }
      } finally {
        statsQuery.refetch();
        setLoadingSave(false);
      }
    }
  };

  const handleOnFormChange = () => {
    onChangedValues(true);
  };

  return (
    <Form
      layout="vertical"
      form={form}
      onFinish={onSubmitForm}
      onChange={handleOnFormChange}
      data-hs-do-not-collect="true"
    >
      {statsQuery.isLoading ? (
        <LoadingSpinner />
      ) : (
        <Row gutter={[32, 32]}>
          {ALL_STATS_WITH_NUMBERS.map((item) => (
            <Col key={item.name} span={12}>
              <Form.Item label={t(item.label)} name={item.name} style={{ width: "100%" }}>
                <InputNumber
                  size="large"
                  style={{ width: "100%" }}
                  min={0}
                  addonAfter={StatUnitMap[item.label] && t(StatUnitMap[item.label])}
                />
              </Form.Item>
            </Col>
          ))}
          <Col span={12}>
            <Form.Item label={t(ECraftsmanStatTypes.SPOKEN_LANGUAGES_STAT)} name="languages">
              <SelectAutocomplete
                mode="multiple"
                placeholder={t("Select")}
                options={SPOKEN_LANGUAGES.map((langCode) => ({
                  value: convertLangForBackend(langCode),
                  label: getLangName(i18n.language, convertLangForBackend(langCode)),
                }))}
                onChange={handleOnFormChange}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Willingness to travel" name="travel">
              <SelectAutocomplete
                sortAlphabetically={false}
                placeholder={t("Select")}
                options={Object.values(EProfileTravelType).map((item) => ({
                  value: item,
                  label: t(item),
                }))}
                onChange={handleOnFormChange}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Row justify="end">
              <Col span={4}>
                <Form.Item>
                  <Button type="primary" htmlType="submit" block loading={loadingSave}>
                    {t("Save")}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
      )}
    </Form>
  );
};
