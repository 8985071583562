import { PhoneOutlined, UserOutlined } from "@ant-design/icons";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { App, Avatar, Button, Col, Row, Space, Tooltip, Typography } from "antd";
import { SelectAutocomplete } from "@app/components/ui/SelectAutocomplete/SelectAutocomplete";
import { VerifiedBadge } from "@app/components/ui/VerifiedBadge";
import { useAdminsSA } from "@app/features/super-admin/api/super-admin.admin.api";
import {
  editUserProgressSA,
  progressQueryKey,
  useUserProgressSA,
} from "@app/features/super-admin/api/super-admin.progress.api";
import { UserFormDef } from "@app/features/super-admin/types/super-admin.user.types";
import { getFullName } from "@app/helpers/name.helper";
import { Goals } from "./Goals/Goals";
import { PointsTag } from "./PointsTag";
import { Reminders, RemindMeButton } from "./Reminders";
import { UserForm } from "./UserForm";
import { UserJobAvailabilityForm } from "./UserJobAvailabilityForm";
import { launchConfetti } from "./utils";

export const DashboardTab = ({ selectedUser }: { selectedUser?: UserFormDef }) => {
  const { modal } = App.useApp();
  const queryClient = useQueryClient();
  const userProgressId = selectedUser?.workerProfileProgressId;

  const adminsQuery = useAdminsSA();
  const userProgressQuery = useUserProgressSA({
    id: userProgressId,
  });

  const selectedUserProgress = userProgressQuery.data;

  const deletedUser = Boolean(selectedUserProgress?.deletedAt);

  const invalidateQueries = () => {
    return Promise.allSettled([
      queryClient.invalidateQueries({
        queryKey: progressQueryKey,
      }),
    ]);
  };

  const editUserProgress = useMutation({
    mutationFn: editUserProgressSA,
    onSettled: () => {
      return invalidateQueries();
    },
  });

  if (!userProgressId || !selectedUserProgress) {
    return null;
  }

  return (
    <>
      <Row align="top" wrap={false} gutter={24}>
        <Col flex="1 0 450px">
          <Space direction="vertical" size="middle" wrap={false}>
            <Row align="middle" gutter={12} wrap={false}>
              <Col>
                <Avatar src={selectedUserProgress.workerProfile.picture} icon={<UserOutlined />} />
              </Col>
              <Col>
                <Typography.Title level={4} style={{ marginBottom: 0, fontWeight: 700 }}>
                  {getFullName(selectedUserProgress.workerProfile)}
                  {selectedUserProgress.workerProfile.isVerified && (
                    <VerifiedBadge style={{ marginLeft: 4 }} />
                  )}
                </Typography.Title>
              </Col>
              <Col>
                <PointsTag userProgress={selectedUserProgress} />
              </Col>
              <Col>
                <SelectAutocomplete
                  loading={editUserProgress.isPending || adminsQuery.isPending}
                  value={selectedUserProgress.assignedSuperAdminId}
                  disabled={deletedUser}
                  placeholder="Admin"
                  size="middle"
                  style={{
                    width: "100%",
                    maxWidth: "350px",
                  }}
                  options={
                    adminsQuery.data?.map((admin) => ({
                      value: admin.id,
                      label: admin.firstName,
                    })) ?? []
                  }
                  onChange={(value) => {
                    editUserProgress.mutate({
                      userProgressId: selectedUserProgress.id,
                      assignedSuperAdminId: value,
                    });
                  }}
                />
              </Col>
            </Row>
            <Row gutter={[8, 8]} hidden={deletedUser}>
              <Col flex="none">
                <Tooltip
                  mouseEnterDelay={0.5}
                  title={
                    selectedUserProgress.workerProfile.phone
                      ? `Call user: ${selectedUserProgress.workerProfile.phone}`
                      : "No phone number"
                  }
                >
                  <Button
                    disabled={!selectedUserProgress.workerProfile.phone}
                    icon={<PhoneOutlined />}
                    href={`tel:${selectedUserProgress.workerProfile.phone}`}
                  />
                </Tooltip>
              </Col>
              <Col flex="none">
                <Button
                  loading={editUserProgress.isPending}
                  onClick={(e) => {
                    const mutation = () => {
                      editUserProgress.mutate({
                        userProgressId: selectedUserProgress.id,
                        isWaitingForAction: !selectedUserProgress.isWaitingForAction,
                      });
                    };

                    if (selectedUserProgress.isWaitingForAction) {
                      modal.confirm({
                        title: "Make user as active",
                        content: "Are you sure you want to mark this user as active?",
                        okText: "Yes",
                        cancelText: "Cancel",
                        onOk: mutation,
                      });
                    } else {
                      mutation();

                      const x = e.clientX / window.innerWidth;
                      const y = e.clientY / window.innerHeight;

                      launchConfetti(x, y);
                    }
                  }}
                >
                  {editUserProgress.isPending
                    ? "🤔"
                    : selectedUserProgress.isWaitingForAction
                    ? "⏰"
                    : "✅"}{" "}
                  {selectedUserProgress.isWaitingForAction ? "Waiting for action" : "Mark as done"}
                </Button>
              </Col>
              <Col flex="none">
                <RemindMeButton
                  workerProfileProgressId={selectedUserProgress.id}
                  defaultDescription={`User: ${selectedUserProgress.workerProfile.firstName} ${selectedUserProgress.workerProfile.lastName}\n\nNote: `}
                />
              </Col>
              <Col flex="none">
                <UserJobAvailabilityForm userId={selectedUserProgress.workerProfileId} />
              </Col>
            </Row>
            <Row hidden={deletedUser}>
              <Reminders workerProfileProgressId={selectedUserProgress.id} />
            </Row>
          </Space>
        </Col>
        <Col flex="0 1 800px">
          {selectedUserProgress && (
            <UserForm
              key={selectedUserProgress.workerProfileId}
              userId={selectedUserProgress.workerProfileId}
            />
          )}
        </Col>
      </Row>

      <Goals userProgress={selectedUserProgress} invalidateQueries={invalidateQueries} />
    </>
  );
};
