import { useQuery } from "@tanstack/react-query";
import { EngagementSADef } from "@app/features/super-admin/types/super-admin.engagement.types";
import { queryKeysSA } from "./queryKeys";
import { superAdminApi } from "./super-admin.api";

export const engagementQueryKey = [queryKeysSA.all, "engagement"] as const;
export const useEngagementSA = (params: {
  workerProfileId: string;
  companyId?: string;
  jobAdId?: string;
}) =>
  useQuery({
    queryKey: [...engagementQueryKey, params] as const,
    queryFn: ({ queryKey }) =>
      superAdminApi
        .get<EngagementSADef>("/crafthunts/engagements", {
          params: queryKey[2],
        })
        .then(({ data }) => data),
  });

export const recommendJobsSA = (
  params: {
    workerProfileId: string;
  } & (
    | {
        engagementType: "manually-recommended";
        jobAdIds: [string];
        internalNote?: string;
      }
    | {
        engagementType: "manually-recommended-to-user";
        jobAdIds: string[];
      }
  )
) => {
  return superAdminApi.put("/crafthunts/engagements", params);
};

export const deleteJobRecommendationSA = (params: {
  companyId: string;
  workerProfileId: string;
}) => {
  return superAdminApi
    .delete("/crafthunts/engagements", {
      params,
    })
    .then(() => Promise.resolve());
};
