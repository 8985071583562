import {
  MDXEditor,
  listsPlugin,
  thematicBreakPlugin,
  linkPlugin,
  linkDialogPlugin,
  toolbarPlugin,
  UndoRedo,
  BoldItalicUnderlineToggles,
  ListsToggle,
  InsertThematicBreak,
  CreateLink,
  MDXEditorProps,
  MDXEditorMethods,
} from "@mdxeditor/editor";
import clsx from "clsx";
import { ForwardedRef } from "react";
import { useTranslation } from "react-i18next";

import "@mdxeditor/editor/style.css";
import styles from "./MarkdownEditor.module.scss";

export type MarkdownEditorInstance = MDXEditorMethods;

export const MarkdownEditor = ({
  value,
  isError,
  editorRef,
  ...props
}: Pick<MDXEditorProps, "placeholder" | "onChange" | "onBlur"> & {
  value?: string;
  isError?: boolean;
  editorRef?: ForwardedRef<MDXEditorMethods> | null;
}) => {
  const { t } = useTranslation();

  return (
    <MDXEditor
      translation={t}
      markdown={value ?? ""}
      contentEditableClassName={clsx(styles.contentEditable, isError && styles.error)}
      plugins={[
        listsPlugin(),
        thematicBreakPlugin(),
        linkPlugin(),
        linkDialogPlugin(),
        toolbarPlugin({
          toolbarClassName: styles.toolbar,
          toolbarContents: () => (
            <>
              <UndoRedo />
              <BoldItalicUnderlineToggles options={["Bold", "Italic"]} />
              <ListsToggle options={["number", "bullet"]} />
              <InsertThematicBreak />
              <CreateLink />
            </>
          ),
        }),
      ]}
      {...props}
      ref={editorRef}
    />
  );
};

// Weird case with multiple spaces https://github.com/mdx-editor/editor/issues/103
export const removeUnicodeSpacesFromMarkdown = (text: string) => text.replace(/&#x20;/g, " ");
