import { useMutation } from "@tanstack/react-query";
import { Alert, App, Form, Spin } from "antd";
import { useEffect, useRef } from "react";
import { updateUserSA, useUserSA } from "@app/features/super-admin/api/super-admin.user.api";
import { UserJobAvailability } from "@app/features/super-admin/components/SuperAdminStuff/components/Users/components/UserModal/components/UserInfoForm/components/UserJobAvailability/UserJobAvailability";
import { EJobAvailability } from "@app/features/super-admin/types/super-admin.user.types";

type UserJobAvailabilityFormProps = {
  userId: string;
};

export const UserJobAvailabilityForm = ({ userId }: UserJobAvailabilityFormProps) => {
  const [form] = Form.useForm<typeof initialValues>();
  const isFocusedRef = useRef(false);
  const { message } = App.useApp();

  const userQuery = useUserSA({
    userId,
  });

  const deletedUser = Boolean(userQuery.data?.deletedAt);
  const jobAvailability = userQuery.data?.getScheduledJobRecommendations
    ? EJobAvailability.OPEN
    : EJobAvailability.CLOSED;

  const initialValues = {
    jobAvailability,
  };

  useEffect(() => {
    if (!isFocusedRef.current) {
      form.setFieldValue("jobAvailability", jobAvailability);
    }
  }, [jobAvailability]);

  const updateUser = useMutation({
    mutationFn: updateUserSA,
    onSuccess: () => {
      message.success("Job availability saved");
    },
    onSettled: () => {
      userQuery.refetch();
    },
  });

  return (
    <>
      {userQuery.isLoading && <Spin />}
      {userQuery.error && <Alert type="error" message="Failed to load user data" />}
      {initialValues && (
        <Form
          form={form}
          data-hs-do-not-collect="true"
          layout="vertical"
          initialValues={initialValues}
          style={{
            width: "100%",
          }}
          onFinish={(values) => {
            updateUser.mutate({
              id: userId,
              getScheduledJobRecommendations: values.jobAvailability === EJobAvailability.OPEN,
            });
          }}
          disabled={deletedUser}
        >
          <Form.Item name="jobAvailability">
            <UserJobAvailability
              onFocus={() => {
                isFocusedRef.current = true;
              }}
              onSelect={(value) => {
                if (value !== jobAvailability) {
                  form.submit();
                }
                isFocusedRef.current = false;
              }}
            />
          </Form.Item>
        </Form>
      )}
    </>
  );
};
