import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  DeleteOutlined,
  FileTwoTone,
  InfoCircleOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import { Button, Card, Col, Divider, Form, Row, Tag, Typography } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { validate } from "uuid";
import { Label } from "@app/components/ui/Label/Label";
import { SelectAutocomplete } from "@app/components/ui/SelectAutocomplete/SelectAutocomplete";
import { UploaderFiles } from "@app/components/ui/UploaderFiles/UploaderFiles";
import { DateFormats } from "@app/constants/date.constants";
import {
  startCVParserProcessSA,
  useCVParserProcessSA,
} from "@app/features/super-admin/api/super-admin.cv-parser.api";
import { ECVParserStatus } from "@app/features/super-admin/types/super-admin.cv-parser.types";
import { UploadFileDef } from "@app/features/super-admin/types/super-admin.files.types";
import {
  DocumentTypeEnum,
  UserFormDef,
} from "@app/features/super-admin/types/super-admin.user.types";

const DEFAULT_DOC_TYPE = DocumentTypeEnum.CV;

export const UserFiles = ({ user, medias }: { user: UserFormDef; medias: UploadFileDef[] }) => {
  const { t } = useTranslation();
  const form = Form.useFormInstance<UserFormDef>();
  const [isParserEnabled, setIsParserEnabled] = useState(false);

  const parserQuery = useCVParserProcessSA(
    {
      workerProfileId: user.id,
      orderBy: [{ fieldName: "createdAt", order: "DESC" }],
    },
    {
      enabled: isParserEnabled,
      refetchInterval: 5000,
    }
  );

  useEffect(() => {
    parserQuery.refetch();
  }, [user.updatedAt]);

  useEffect(() => {
    if (parserQuery.data) {
      setIsParserEnabled(
        parserQuery.data.data.some((item) =>
          [ECVParserStatus.QUEUED, ECVParserStatus.PROCESSING].includes(item.status)
        )
      );
    }
  }, [parserQuery.data?.data]);

  const getDocumentType = (fileUrl?: string) => {
    if (!fileUrl) {
      return DEFAULT_DOC_TYPE;
    }
    return medias?.find((doc) => doc.url === fileUrl)?.type || DEFAULT_DOC_TYPE;
  };

  const handleChangeDocumentType = (value: string, fileUrl?: string) => {
    if (!medias || !fileUrl) {
      return;
    }
    const docIndex = medias.findIndex((doc) => doc.url === fileUrl);
    if (docIndex !== -1) {
      const newMedias = JSON.parse(JSON.stringify(medias));
      newMedias[docIndex].type = value;
      form.setFieldValue("medias", newMedias);
    }
  };

  const handleRetryParser = async (file: UploadFileDef) => {
    if (!file.url) {
      return;
    }
    await startCVParserProcessSA(user.id, [
      {
        url: file.url,
        filename: file.fileName || file.name,
        type: file.type,
      },
    ]);
    parserQuery.refetch();
  };

  const getProcessTag = (file?: UploadFileDef) => {
    if (!file || !file.url) {
      return null;
    }
    const process = parserQuery.data?.data?.find((item) =>
      item.medias.find((media) => media.url === file.url)
    )?.status;

    switch (process) {
      case ECVParserStatus.QUEUED:
        return (
          <Tag icon={<ClockCircleOutlined />} color="default">
            in parser queue
          </Tag>
        );
      case ECVParserStatus.PROCESSING:
        return (
          <Tag icon={<SyncOutlined spin />} color="processing">
            parsing file
          </Tag>
        );
      case ECVParserStatus.COMPLETED:
        return (
          <Tag icon={<CheckCircleOutlined />} color="success">
            parsed
          </Tag>
        );
      case ECVParserStatus.FAILED:
        return (
          <>
            <Tag icon={<CloseCircleOutlined />} color="error">
              failed parsing
            </Tag>
            <Button type="primary" onClick={() => handleRetryParser(file)}>
              Retry
            </Button>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <Form.Item label={t("Documents")} name={"medias"}>
      <UploaderFiles
        initialFiles={medias || []}
        onChange={(docs) => {
          form.setFieldValue(
            "medias",
            docs.map((doc) => ({
              ...doc,
              type: doc.type || DEFAULT_DOC_TYPE,
            }))
          );
        }}
        itemRender={(_originNode, file: UploadFileDef, _fileList, actions) => {
          const processTag = getProcessTag(file);
          const isFileUploaded = validate(file.uid);
          return (
            <>
              <Row align="bottom" gutter={12} wrap={false}>
                <Col flex="auto">
                  <Card size="small" style={{ marginTop: 8 }}>
                    <Row gutter={4} wrap={false} align={"middle"}>
                      <Col flex="40px">
                        <Button
                          type="link"
                          icon={<FileTwoTone style={{ fontSize: 25 }} />}
                          href={file.url}
                          target="_blank"
                          style={{ fontSize: 25 }}
                        />
                      </Col>
                      <Col flex="auto">
                        <Row wrap={false}>
                          <Col>
                            <Typography.Link href={file.url} target="_blank" ellipsis>
                              {file.name}
                            </Typography.Link>
                          </Col>
                        </Row>
                        {isFileUploaded && !!file.createdAt && (
                          <Row wrap={false}>
                            <Col>
                              <Typography.Text type="secondary" style={{ fontSize: 14 }}>
                                {moment.utc(file.createdAt).format(DateFormats.FULL_DATE_TIME)}
                              </Typography.Text>
                            </Col>
                          </Row>
                        )}
                      </Col>
                      {isFileUploaded && Boolean(processTag) && <Col>{processTag}</Col>}
                      {!isFileUploaded && (
                        <Tag icon={<InfoCircleOutlined />} color="default">
                          save to start parsing
                        </Tag>
                      )}
                      <Col flex="34px">
                        <Button
                          type="link"
                          onClick={() => actions.remove()}
                          icon={<DeleteOutlined />}
                        />
                      </Col>
                    </Row>
                  </Card>
                </Col>
                <Col flex="200px">
                  <Label htmlFor={`type-${file.url}`}>{t("Type")}:</Label>
                  <SelectAutocomplete
                    id={`type-${file.url}`}
                    value={getDocumentType(file.url)}
                    placeholder={t("Select")}
                    options={Object.values(DocumentTypeEnum).map((item) => ({
                      value: item,
                      label: item,
                    }))}
                    sortAlphabetically={false}
                    onChange={(val) => handleChangeDocumentType(val, file.url)}
                  />
                </Col>
              </Row>

              <Divider style={{ margin: "8px 0" }} />
            </>
          );
        }}
      />
    </Form.Item>
  );
};
