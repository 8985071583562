import { Checkbox, Col, Form, Input, InputNumber, Typography } from "antd";
import { FormInstance, Rule, RuleObject } from "antd/lib/form";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { JobAdFormSection } from "@app/components/pages/JobAdDetails/components/JobAdFormSection/JobAdFormSection";
import { AddressPicker, EAddressPickerType } from "@app/components/ui/AddressPicker/AddressPicker";
import { MomentDatePicker } from "@app/components/ui/DatePicker";
import { MarkdownEditor } from "@app/components/ui/Markdown/MarkdownEditor";
import { SelectAutocomplete } from "@app/components/ui/SelectAutocomplete/SelectAutocomplete";
import { CropAspectEnum, Uploader } from "@app/components/ui/Uploader/Uploader";
import { currencies } from "@app/constants/currencies.constants";
import { DateFormats } from "@app/constants/date.constants";
import {
  ALLOWED_COUNTRIES_FOR_JOB,
  SPOKEN_LANGUAGES,
  getCountryCodeByCountry,
} from "@app/constants/stat.constants";
import { getLangName } from "@app/helpers/language.helper";
import { getTradeName } from "@app/helpers/tag.helper";
import { RootState } from "@app/store/store";
import {
  EJobAdStatus,
  EJobDuration,
  EJobTravelType,
  ESalaryFrequency,
  JobAdFormDef,
} from "@app/types/job-ads.types";
import { JobAdCompanyIntegration } from "./components/JobAdCompanyIntegration/JobAdCompanyIntegration";

const INITIAL_DATA: JobAdFormDef = {
  coverImage: null,
  title: "",
  description: "",
  roleId: "",
  tradeId: "",
  salaryMin: null,
  salaryMax: null,
  currency: "EUR",
  salaryFrequency: ESalaryFrequency.MONTHLY,
  salaryHidden: false,
  status: EJobAdStatus.DRAFT,
  requiredLanguages: [],
  requiredCountries: [],
  travel: EJobTravelType.NO_TRAVEL,
  duration: EJobDuration.UNLIMITED,
  workingHours: null,
  projectTypesId: [],
  jobStartDate: null,
  jobEndDate: null,
  address: null,
  coordinate: null,
  useCompanyAddress: true,
  temp_address: null,
  integrationJobAdId: null,
  integrationStatusMapId: null,
  integrationStatusMap: null,
};

type JobAdFormProps = {
  form: FormInstance<JobAdFormDef>;
  onFinishFailed: () => void;
  isFetchingData: boolean;
  isCreateNewJobAd: boolean;
  hasJobAdBeenPublished: boolean;
  onCoverImageChange: () => void;
};

export const JobAdForm = ({
  form,
  onFinishFailed,
  isFetchingData,
  isCreateNewJobAd,
  hasJobAdBeenPublished,
  onCoverImageChange,
}: JobAdFormProps) => {
  const { t, i18n } = useTranslation();
  const hasIntegrations = useSelector(
    (state: RootState) => state.companyInfo.companyInfo.hasIntegrations
  );
  const jobTitles = useSelector((state: RootState) => state.tags.jobTitles);
  const availableTradesTags = useSelector((state: RootState) => state.tags.trades);
  const availableProjectTypes = useSelector((state: RootState) => state.tags.projectTypes);
  const companyAddress = useSelector((state: RootState) => state.companyInfo.companyInfo.address);
  const companyCoordinates = useSelector(
    (state: RootState) => state.companyInfo.companyInfo.coordinates
  );
  const useCompanyAddress = Form.useWatch("useCompanyAddress", form);
  const hasCoverImage = Form.useWatch("coverImage", form);
  const selectedRoleId = Form.useWatch("roleId", form);
  const existingCoverImages = [form.getFieldValue("coverImage")].filter((i) => i) as string[];

  // CHOICES
  const projectTypeChoices = availableProjectTypes.map((i) => ({
    value: i.id,
    label: t(i.name),
  }));
  const currencyChoices = currencies.map((currency) => ({
    value: currency.abbreviation,
    label: currency.abbreviation,
  }));
  const jobTitleChoices = jobTitles.map((i) => ({
    value: i.id,
    label: t(i.name),
    id: `job-title-${i.id}`,
  }));
  const [tradesChoices, setTradesChoices] = useState(
    availableTradesTags.map((i) => ({
      value: i.id,
      label: t(i.name),
      id: `trade-${i.id}`,
    }))
  );

  // RULES
  const requiredRule: Rule = { required: true, whitespace: true, message: t("required") };
  const salaryRule = {
    validator: (rule: RuleObject, value: number) => {
      return new Promise((resolve, reject) => {
        let isSalaryValid = true;
        const min = form.getFieldValue("salaryMin");
        const max = form.getFieldValue("salaryMax");
        if (!min && !max) {
          if (typeof min !== "number" || typeof max !== "number") {
            isSalaryValid = false;
            reject(new Error(t("Please fill out at least one")));
          }
        }
        if (min && max) {
          if (typeof min === "number" && typeof max === "number") {
            isSalaryValid = min <= max;
          }
        }
        if (isSalaryValid) {
          resolve(value);
        } else {
          reject(new Error(t("Invalid salary range")));
        }
      });
    },
  };

  const handleOnFinishFailed = () => {
    onFinishFailed();
  };

  const handleOnCoverImagesChange = (newImages: string[]) => {
    form.setFieldValue("coverImage", newImages[0] || null);
    onCoverImageChange();
  };

  useEffect(() => {
    if (selectedRoleId) {
      const selectedRole = jobTitles.find((i) => i.id === selectedRoleId);
      if (selectedRole) {
        setTradesChoices(
          availableTradesTags.map((i) => ({
            value: i.id,
            label: t(getTradeName(i.name, selectedRole.category)),
            id: `trade-${i.id}`,
          }))
        );
      }
    }
  }, [selectedRoleId]);

  useEffect(() => {
    if (!isFetchingData && useCompanyAddress && companyAddress && isCreateNewJobAd) {
      form.setFieldValue("temp_address", {
        address: companyAddress,
        coordinates: companyCoordinates,
      });
      const countryCode = getCountryCodeByCountry(companyAddress.country);
      if (
        ALLOWED_COUNTRIES_FOR_JOB.find((allowedCountryCode) => allowedCountryCode === countryCode)
      ) {
        form.setFieldValue("requiredCountries", [countryCode]);
      }
    }
  }, [useCompanyAddress, companyAddress, isFetchingData, isCreateNewJobAd]);

  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={INITIAL_DATA}
      name="job-ad-form"
      onFinishFailed={handleOnFinishFailed}
      data-hs-do-not-collect="true"
    >
      <div>
        <Typography.Title level={3}>{t("Info")}</Typography.Title>
      </div>
      <Form.Item hidden name="status" noStyle>
        <Input />
      </Form.Item>
      {/* COVER IMAGE */}
      <JobAdFormSection title="Cover Image">
        <Col span={24}>
          <Form.Item
            name="coverImage"
            help={
              !hasCoverImage &&
              t("If you don't upload a cover image, your company cover photo will be used")
            }
          >
            <Uploader
              allowCrop
              cropAspect={CropAspectEnum.COVER_IMAGE}
              existingMediaArray={existingCoverImages}
              onImagesChange={handleOnCoverImagesChange}
              multiple={false}
            />
          </Form.Item>
        </Col>
      </JobAdFormSection>
      {/* DETAILS */}
      <JobAdFormSection title="Details">
        <Col span={24}>
          <Form.Item label={t("Job ad ID")} name="slug">
            <Input style={{ width: "100%" }} disabled />
          </Form.Item>
        </Col>

        <Col span={24}>
          <Form.Item
            rules={[requiredRule]}
            label={t("Job title")}
            name="title"
            required
            help={t("The job title cannot be edited after published, duplicate the job instead.")}
            style={{ marginBottom: 32 }}
          >
            <Input.TextArea
              size="large"
              autoSize={{ minRows: 1, maxRows: 2 }}
              style={{ width: "100%" }}
              placeholder={t("Enter...")}
              maxLength={255}
              disabled={hasJobAdBeenPublished}
            />
          </Form.Item>
        </Col>
        <Col span={24} md={12}>
          <Form.Item
            id="role-select"
            rules={[requiredRule]}
            label={t("Role")}
            name="roleId"
            required
          >
            <SelectAutocomplete placeholder={t("Select")} options={jobTitleChoices} />
          </Form.Item>
        </Col>
        <Col span={24} md={12}>
          <Form.Item
            id="trade-select"
            rules={[requiredRule]}
            label={t("Trade")}
            name="tradeId"
            required
          >
            <SelectAutocomplete placeholder={t("Select")} options={tradesChoices} />
          </Form.Item>
        </Col>
      </JobAdFormSection>
      {/* Company Integration */}
      {hasIntegrations && <JobAdCompanyIntegration />}
      {/* ABOUT */}
      <JobAdFormSection title="About">
        {!isFetchingData && (
          <>
            <Col span={24}>
              <Form.Item
                label={t("Address")}
                name="temp_address"
                style={{ marginBottom: 0 }}
                help={t(
                  "The job address cannot be edited after published, duplicate the job instead."
                )}
              >
                <AddressPicker
                  disabled={useCompanyAddress || hasJobAdBeenPublished}
                  type={EAddressPickerType.CITY}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="useCompanyAddress" valuePropName="checked">
                <Checkbox disabled={hasJobAdBeenPublished}>{t("Use company address")}</Checkbox>
              </Form.Item>
            </Col>
          </>
        )}

        <Col span={24} md={12}>
          <Form.Item label={t("Required languages")} name="requiredLanguages">
            <SelectAutocomplete
              mode="multiple"
              placeholder={t("Required languages")}
              allowClear
              options={SPOKEN_LANGUAGES.map((langCode) => ({
                value: langCode,
                label: getLangName(i18n.language, langCode),
              }))}
            />
          </Form.Item>
        </Col>
        <Col span={24} md={12}>
          <Form.Item
            label={t("Allowed countries")}
            name="requiredCountries"
            help={t("Only allow applications from the selected countries")}
          >
            <SelectAutocomplete
              mode="multiple"
              placeholder={t("Allowed countries")}
              allowClear
              options={ALLOWED_COUNTRIES_FOR_JOB.map((countryCode) => ({
                value: countryCode,
                label: getLangName(i18n.language, countryCode, "region"),
              }))}
            />
          </Form.Item>
        </Col>

        <Col span={24} md={12}>
          <Form.Item label={t("Travel")} name="travel">
            <SelectAutocomplete
              placeholder={t("Travel")}
              options={Object.values(EJobTravelType).map((item) => ({
                value: item,
                label: t(`${item}-travel`),
              }))}
            />
          </Form.Item>
        </Col>
        <Col span={24} md={12}>
          <Form.Item label={t("Project types")} name="projectTypesId">
            <SelectAutocomplete
              mode="multiple"
              placeholder={t("Select")}
              allowClear
              options={projectTypeChoices}
            />
          </Form.Item>
        </Col>
        {!isFetchingData && (
          <Col span={24}>
            <Form.Item rules={[requiredRule]} label={t("Description")} name="description" required>
              <MarkdownEditor placeholder={t("Enter...")} />
            </Form.Item>
          </Col>
        )}
      </JobAdFormSection>
      {/* TIME AND SALARY */}
      <JobAdFormSection title="Time and salary">
        <Col span={24} md={12}>
          <Form.Item label={t("Job start date")} name="jobStartDate">
            <MomentDatePicker
              size="large"
              format={DateFormats.D_M_Y}
              allowClear
              placeholder={t("DD/MM/YYYY")}
              style={{ width: "100%" }}
              disabledDate={(current) =>
                form.getFieldValue("jobEndDate")
                  ? current > form.getFieldValue("jobEndDate")
                  : false
              }
            />
          </Form.Item>
        </Col>
        <Col span={24} md={12}>
          <Form.Item label={t("Job end date")} name="jobEndDate">
            <MomentDatePicker
              size="large"
              format={DateFormats.D_M_Y}
              allowClear
              placeholder={t("DD/MM/YYYY")}
              style={{ width: "100%" }}
              disabledDate={(current) => current < form.getFieldValue("jobStartDate")}
            />
          </Form.Item>
        </Col>
        <Col span={24} md={12}>
          <Form.Item label={t("Duration")} name="duration">
            <SelectAutocomplete
              placeholder={t("Duration")}
              options={Object.values(EJobDuration).map((item) => ({
                value: item,
                label: t(`${item}-duration`),
              }))}
            />
          </Form.Item>
        </Col>
        <Col span={24} md={12}>
          <Form.Item label={t("Hours per week")} name="workingHours">
            <InputNumber
              size="large"
              type="number"
              style={{ width: "100%" }}
              placeholder={"0"}
              min={0}
            />
          </Form.Item>
        </Col>
        <Col span={24} md={12} xl={6}>
          <Form.Item
            rules={[salaryRule]}
            dependencies={["salaryMax"]}
            label={t("Salary minimum")}
            name="salaryMin"
            required
          >
            <InputNumber
              size="large"
              type="number"
              style={{ width: "100%" }}
              placeholder={"0"}
              min={0}
            />
          </Form.Item>
        </Col>
        <Col span={24} md={12} xl={6}>
          <Form.Item
            rules={[salaryRule]}
            dependencies={["salaryMin"]}
            label={t("Salary maximum")}
            name="salaryMax"
            required
          >
            <InputNumber
              size="large"
              type="number"
              style={{ width: "100%" }}
              placeholder={"0"}
              min={0}
            />
          </Form.Item>
        </Col>
        <Col span={24} md={8} xl={4}>
          <Form.Item label={t("Currency")} name="currency">
            <SelectAutocomplete options={currencyChoices} />
          </Form.Item>
        </Col>
        <Col span={24} md={16} xl={8}>
          <Form.Item label={t("Frequency")} name="salaryFrequency">
            <SelectAutocomplete
              options={Object.values(ESalaryFrequency).map((item) => ({
                value: item,
                label: t(`${item}-salary-frequency`),
              }))}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item name="salaryHidden" valuePropName="checked">
            <Checkbox>{t("Hide salary for applicant")}</Checkbox>
          </Form.Item>
        </Col>
      </JobAdFormSection>
    </Form>
  );
};
