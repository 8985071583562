import { Flex, Modal, Space, Tabs, Tag, Typography } from "antd";
import moment from "moment";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { VerifiedBadge } from "@app/components/ui/VerifiedBadge";
import { DateFormats } from "@app/constants/date.constants";
import { useCandidateApprovalsSA } from "@app/features/super-admin/api/super-admin.approvals.api";
import { useCandidateSA } from "@app/features/super-admin/api/super-admin.candidate.api";
import { useCompanyModal } from "@app/features/super-admin/components/SuperAdminStuff/components/Companies/components/CompanyModal";
import { useUserModalGlobal } from "@app/features/super-admin/components/SuperAdminStuff/components/Users/components/UserModal/UserModalGlobal";
import { ApprovalHistory } from "@app/features/super-admin/components/SuperAdminStuff/components/Users/components/UserModal/components/ApprovalHistory/ApprovalHistory";
import { getFullName } from "@app/helpers/name.helper";
import { ECandidateMethod, ECandidateStatus } from "@app/types/candidate.types";
import { ChatTab } from "./ChatTab";
import { InfoTab } from "./InfoTab/InfoTab";
import { useApplicationModal } from "./useApplicationModal";

enum TAB_KEYS {
  INFO = "info",
  CHAT = "chat",
  APPROVAL_HISTORY = "approval-history",
}

export const ApplicationModal = () => {
  const { t } = useTranslation();
  const { applicationId, setApplicationId } = useApplicationModal();
  const [activeTab, setActiveTab] = useState(TAB_KEYS.INFO);
  const { openUserModal } = useUserModalGlobal();
  const { setCompanyId } = useCompanyModal();
  const candidateQuery = useCandidateSA({
    id: applicationId,
  });
  const approvalHistoryQuery = useCandidateApprovalsSA({
    workerProfileId: candidateQuery.data?.workerProfile.id,
    limit: 1,
  });

  const candidate = candidateQuery.data;

  return (
    <Modal
      open={Boolean(applicationId)}
      width="100%"
      footer={null}
      zIndex={999}
      style={{ top: 20 }}
      title={
        <>
          User:{" "}
          {candidate && (
            <a
              onClick={() =>
                openUserModal({
                  userId: candidate.workerProfile.id,
                })
              }
            >
              {getFullName(candidate.workerProfile)}
              {candidate.workerProfile.isVerified && <VerifiedBadge style={{ marginLeft: 4 }} />}
            </a>
          )}
          {" - "}
          {candidate && (
            <>
              Company:{" "}
              <a onClick={() => setCompanyId(candidate.crafthuntCompany.id)}>
                {candidate.crafthuntCompany.name}
              </a>
            </>
          )}
        </>
      }
      onCancel={() => setApplicationId(null)}
    >
      {applicationId && (
        <Tabs
          activeKey={activeTab}
          onChange={(tabKey) => setActiveTab(tabKey as TAB_KEYS)}
          items={[
            {
              key: TAB_KEYS.INFO,
              label: "Info",
              children: <InfoTab candidateId={applicationId} />,
            },
            {
              key: TAB_KEYS.CHAT,
              label: "Chat",
              children: <ChatTab candidateId={applicationId} />,
            },
            {
              key: TAB_KEYS.APPROVAL_HISTORY,
              label: `Approval History ${
                approvalHistoryQuery.data ? `(${approvalHistoryQuery.data.count})` : ""
              }`,
              children: <ApprovalHistory workerProfileId={candidate?.workerProfile.id} />,
            },
          ]}
          tabBarExtraContent={
            candidate && (
              <Flex vertical align="flex-end">
                <Space size="middle">
                  <Typography.Text
                    type="secondary"
                    style={{
                      fontSize: "12px",
                    }}
                  >
                    Updated: {moment(candidate.updatedAt).format(DateFormats.FULL_DATE_TIME)}
                  </Typography.Text>
                  <Typography.Text
                    type="secondary"
                    style={{
                      fontSize: "12px",
                    }}
                  >
                    Created: {moment(candidate.createdAt).format(DateFormats.FULL_DATE_TIME)}
                  </Typography.Text>
                </Space>
                <Space size="small">
                  {!candidate.crafthuntCompany.isClaimed && (
                    <Tag style={{ margin: 0 }} color="red">
                      Scraped
                    </Tag>
                  )}
                  <Tag
                    style={{ margin: 0 }}
                    color={candidate.method === ECandidateMethod.CONTACTED ? "cyan" : undefined}
                  >
                    Method:{" "}
                    {candidate.isCrafthuntRecommended
                      ? "Recommended"
                      : candidate.method === ECandidateMethod.APPLIED
                      ? "Applied"
                      : "Active Source"}
                  </Tag>
                  <Tag
                    style={{ margin: 0 }}
                    color={
                      candidate.companyCandidateStatus?.status === ECandidateStatus.REJECTED
                        ? "red"
                        : candidate.companyCandidateStatus?.status ===
                          ECandidateStatus.NEEDS_APPROVAL
                        ? "orange"
                        : undefined
                    }
                  >
                    Status:{" "}
                    {candidate.companyCandidateStatus
                      ? candidate.companyCandidateStatus.status === ECandidateStatus.CUSTOM
                        ? candidate.companyCandidateStatus.name
                        : t(candidate.companyCandidateStatus.name)
                      : "Unknown"}
                  </Tag>
                </Space>
              </Flex>
            )
          }
        />
      )}
    </Modal>
  );
};
