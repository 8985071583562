import MarkdownReact from "marked-react";
import { ComponentProps } from "react";

type MarkdownProps = {
  gfm?: boolean;
  isInline?: boolean;
  children?: string;
  renderer?: ComponentProps<typeof MarkdownReact>["renderer"];
};

export const MarkdownRenderer = ({ gfm, isInline, renderer, children }: MarkdownProps) => {
  return (
    <MarkdownReact
      gfm={gfm}
      isInline={isInline}
      renderer={{
        ...renderer,
      }}
    >
      {children}
    </MarkdownReact>
  );
};
