import { Input, Modal } from "antd";
import { useState } from "react";

type RecommendInternalNoteModalProps = {
  onCancel: () => void;
  onRecommend: (internalNote: string | undefined) => void;
  open: boolean;
};

export const RecommendInternalNoteModal = ({
  onCancel,
  onRecommend,
  open,
}: RecommendInternalNoteModalProps) => {
  const [internalNote, setInternalNote] = useState<string | undefined>(undefined);
  return (
    <Modal
      open={open}
      title="Add internal note to the company about this user? (Optional)"
      okText="Recommend"
      onCancel={onCancel}
      onOk={() => onRecommend(internalNote)}
    >
      <Input.TextArea
        autoSize={{ minRows: 4, maxRows: 10 }}
        onChange={(e) => {
          setInternalNote(e.target.value);
        }}
      />
    </Modal>
  );
};
