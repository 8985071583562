export enum ETagType {
  Certificate = "Certificate",
  Crafthunt_Trade = "Crafthunt-Trade",
  Crafthunt_Project = "Crafthunt-Project",
  Crafthunt_Project_Category = "Crafthunt-Project-Category",
  Crafthunt_JobTitle = "Crafthunt-JobTitle",
  Crafthunt_Industry = "Crafthunt-Industry",
}

export enum ETagCategory {
  ON_SITE = "on-site-category-type",
  COMMERCIAL = "commercial-category-type",
  DRIVING_LICENCE = "driving-licence-certificate",
  DEGREE = "degree-certificate",
}

export enum ETradeCategoryType {
  ELECTRICIAN = "electrician",
}

export interface TagDef<T extends ETagType> {
  id: string;
  name: string;
  type: T;
  categoryId: string;
  category: ETagCategory;
  tradeCategory: ETradeCategoryType | null;
  similarTags?: TagDef<ETagType.Crafthunt_JobTitle | ETagType.Crafthunt_Trade>[];
  similarTagsId?: string[];
  totalCount?: number;
  definition?: string;
  imageUrl?: string;
  sortOrder?: null;
  createdAt: string;
  updatedAt: string;
}
export type JobTitleTagDef = TagDef<ETagType.Crafthunt_JobTitle>;
export type TradeTagDef = TagDef<ETagType.Crafthunt_Trade>;
export type IndustryTagDef = TagDef<ETagType.Crafthunt_Industry>;
export type ProjectTypeTagDef = TagDef<ETagType.Crafthunt_Project_Category>;
export type CertificateTagDef = TagDef<ETagType.Certificate>;
