import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Divider, Form, Input, Select, Switch } from "antd";
import { useTranslation } from "react-i18next";
import { SelectAutocomplete } from "@app/components/ui/SelectAutocomplete/SelectAutocomplete";
import { ECompanySubscriptionType } from "@app/features/super-admin/types/super-admin.company.types";

export const CompanySubscriptions = () => {
  const { t } = useTranslation();

  return (
    <>
      <Form.Item
        label={t("Has paid for job ads")}
        name={"hasPaidForJobAds"}
        valuePropName="checked"
      >
        <Switch checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} />
      </Form.Item>
      <Divider />
      <Form.Item
        label={t("has-integrations-toggle")}
        name={"hasIntegrations"}
        valuePropName="checked"
        help={t("has-integrations-help")}
      >
        <Switch checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} />
      </Form.Item>
      <Divider />
      <Form.Item label={"Subscription type"} name={"subscriptionType"}>
        <SelectAutocomplete placeholder={t("Select")}>
          <Select.Option value={null} label={"None"}>
            None
          </Select.Option>
          {Object.values(ECompanySubscriptionType).map((item) => (
            <Select.Option key={item} value={item} label={item}>
              {item}
            </Select.Option>
          ))}
        </SelectAutocomplete>
      </Form.Item>
      <Form.Item label="Max number of hires" name="maxNumberOfHires">
        <Input type="number" />
      </Form.Item>
    </>
  );
};
